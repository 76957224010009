
import React,{useEffect,useState,useRef,useCallback} from 'react';
import {apiPostList,apiPostSearch,apiHashtagFollow} from'./lookup'
import{Post} from './detail'
import {GigaPost} from './gigaPost'

export function PostList(props) {
    const [postsInit, setPostsInit] = useState([]);
    const [posts, setPosts] = useState([]);
    const [nextUrl, setNextUrl] = useState(null);
    const [column1Posts, setColumn1Posts] = useState([]);
    const [column2Posts, setColumn2Posts] = useState([]);
    const [column3Posts, setColumn3Posts] = useState([]);
    const [postsDidSet, setPostsDidSet] = useState(false);
    const [isVideoMuted, setIsVideoMuted] = useState(true);

    const lastIndexRef = useRef(0);
    const loadPostsInterval = useRef(null);

    const startLoadingPosts = useCallback(() => {
      if (loadPostsInterval.current) clearInterval(loadPostsInterval.current);

      loadPostsInterval.current = setInterval(() => {
          if (lastIndexRef.current < posts.length) {
              const post = posts[lastIndexRef.current];

              if (lastIndexRef.current % 3 === 0) {
                  setColumn1Posts(prevPosts => [...prevPosts, post]);
              } else if (lastIndexRef.current % 3 === 1) {
                  setColumn2Posts(prevPosts => [...prevPosts, post]);
              } else {
                  setColumn3Posts(prevPosts => [...prevPosts, post]);
              }
              lastIndexRef.current++;
          } else {
              clearInterval(loadPostsInterval.current);
          }
      }, 50); // Adjust interval as needed
  }, [posts]);

  useEffect(() => {
      if (postsDidSet) {
          startLoadingPosts();
      }
  }, [postsDidSet, startLoadingPosts]);

  useEffect(() => {
      if (!postsDidSet) {
          const handlePostListLookup = (response, status) => {
              if (status === 200) {
                  setNextUrl(response.next);
                  setPostsInit(response.results);
                  setPosts(response.results);
                  setPostsDidSet(true);
              }
          }
          apiPostList(props.username, handlePostListLookup);
      }
  }, [postsInit, postsDidSet, props.username]);

  const handleLoadNext = useCallback((event) => {
      event.preventDefault();
      if (nextUrl !== null) {
          const handleLoadNextResponse = (response, status) => {
              if (status === 200) {
                  setNextUrl(response.next);
                  const newPosts = [...posts, ...response.results];
                  setPostsInit(newPosts);
                  setPosts(newPosts);
                  startLoadingPosts();
              }
          };
          apiPostList(props.username, handleLoadNextResponse, nextUrl);
      }
  }, [nextUrl, posts, props.username, startLoadingPosts]);

  // Memoize the GigaPost component
  const MemoizedGigaPost = React.memo(GigaPost);

  const handleMuteChange = (muted) => {
    setIsVideoMuted(muted);
  };
 return (
    <React.Fragment>
      <div id="column-container">
        <div className="custom-column">
          {column1Posts.map((post, index) => (
            <div className="column-item" key={`${index}-${post.id}`}>
              <GigaPost
                post={post}
                index={index}
                onMuteChange={handleMuteChange}

                didRepost={props.handleDidRepost}
                className="px-2 py-2 bg-white text-dark"
              />
            </div>
          ))}
        </div>
        <div className="custom-column">
          {column2Posts.map((post, index) => (
            <div className="column-item" key={`${index}-${post.id}`}>
              <GigaPost
                post={post}
                index={index}
                onMuteChange={handleMuteChange}

                didRepost={props.handleDidRepost}
                className="px-2 py-2 bg-white text-dark"
              />
            </div>
          ))}
        </div>
        <div className="custom-column">
          {column3Posts.map((post, index) => (
            <div className="column-item" key={`${index}-${post.id}`}>
              <GigaPost
                post={post}
                index={index}
                onMuteChange={handleMuteChange}

                didRepost={props.handleDidRepost}
                className="px-2 py-2 bg-white text-dark"
              />
            </div>
          ))}
        </div>
      </div>
      {nextUrl !== null && (
        <center>
          <button
            onClick={handleLoadNext}
            id="load_more"
            className="btn btn-sm btn-outline-primary"
            style={{ marginBottom: "15vh" }}
          >
            Load More
          </button>
        </center>
      )}
    </React.Fragment>
  );
}




export function PostListSearch(props) {
  const [postsInit, setPostsInit] = useState([]);
  const [posts, setPosts] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [postsDidSet, setPostsDidSet] = useState(false);
  const [Following, setFollowing] = useState(false);
  const [column1Posts, setColumn1Posts] = useState([]);
  const [column2Posts, setColumn2Posts] = useState([]);
  const [column3Posts, setColumn3Posts] = useState([]);
  const [isVideoMuted, setIsVideoMuted] = useState(true);

  const lastIndexRef = useRef(0);
  const loadPostsInterval = useRef(null);

  const startLoadingPosts = useCallback(() => {
      if (loadPostsInterval.current) clearInterval(loadPostsInterval.current);
      loadPostsInterval.current = setInterval(() => {
          if (lastIndexRef.current < posts.length) {
              const post = posts[lastIndexRef.current];
              if (lastIndexRef.current % 3 === 0) {
                  setColumn1Posts(prevPosts => [...prevPosts, post]);
              } else if (lastIndexRef.current % 3 === 1) {
                  setColumn2Posts(prevPosts => [...prevPosts, post]);
              } else {
                  setColumn3Posts(prevPosts => [...prevPosts, post]);
              }
              lastIndexRef.current++;
          } else {
              clearInterval(loadPostsInterval.current);
          }
      }, 50);
  }, [posts]);

  useEffect(() => {
      if (postsDidSet) {
          startLoadingPosts();
      }
  }, [postsDidSet, startLoadingPosts]);

  useEffect(() => {
      if (!postsDidSet) {
          const handlePostListLookup = (response, status) => {
              if (status === 200) {
                  setNextUrl(response.next);
                  setPostsInit(response.results);
                  setPosts(response.results);
                  setPostsDidSet(true);
                  setFollowing(response.isFollowing);
              }
          };
          apiPostSearch(handlePostListLookup);
      }
  }, [postsInit, postsDidSet]);

  const handleLoadNext = useCallback((event) => {
      event.preventDefault();
      if (nextUrl !== null) {
          const handleLoadNextResponse = (response, status) => {
              if (status === 200) {
                  setNextUrl(response.next);
                  const newPosts = [...posts, ...response.results];
                  setPostsInit(newPosts);
                  setPosts(newPosts);
                  startLoadingPosts();
              }
          };
          apiPostSearch(handleLoadNextResponse, nextUrl);
      }
  }, [nextUrl, posts, startLoadingPosts]);

  // ... other handlers and functions ...

  const MemoizedGigaPost = React.memo(GigaPost);

    var search = decodeURIComponent(window.location.href.split('/').slice(-1)[0]);
    var display = 'Posts';
    if(search && search.includes('#')){
        display = search;
    }
    const handleFollowResponse = (response) => {
        console.log(response);
    };
    const handleFollowClick = () => {
        setFollowing(!Following);
        apiHashtagFollow(search, handleFollowResponse);
    };



    function canAutoplayWithSound() {
      return new Promise((resolve, reject) => {
          const audio = new Audio();  // Create an audio element
          audio.src = 'https://www.myinstants.com/media/sounds/1sec_silence.mp3';  // You can use a very short silent audio clip
          audio.volume  = 0.0001;
  
          audio.play().then(() => {
              resolve(true);  // Autoplay with sound is allowed
          }).catch(() => {
              resolve(false);  // Autoplay with sound is not allowed
          });
      });
  }
  
  // Usage:
  canAutoplayWithSound().then(isAllowed => {
      setIsVideoMuted(!isAllowed);  // Set muted state based on whether autoplay with sound is allowed
  });

    const handleMuteChange = (muted) => {
      setIsVideoMuted(muted);
    };

    
   return (
        <React.Fragment>
            {/* Header Section */}
            <div style={{ paddingLeft:'25px',paddingRight:'25px',display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>{search}</h1>
                {display !== 'Posts' && 
                    (Following ?
                        <button onClick={handleFollowClick} style={{float: 'right', height:'42px'}} className="btn btn-sm btn-outline-primary hashtag-follow">Unfollow</button>
                        :
                        <button onClick={handleFollowClick} style={{float: 'right', height:'42px'}} className="btn btn-sm btn-outline-primary hashtag-follow">Follow</button>
                    )
                }
            </div>
            <hr />

            {/* Grid Columns */}
            <div id="column-container">
                <div className="custom-column">
                    {column1Posts.map((post, index) => (
                        <div className="column-item" key={`${index}-${post.id}`}>
                            <GigaPost
                                post={post}
                                index={index}

                                isVideoMuted={isVideoMuted}
                                onMuteChange={handleMuteChange}
                                recommended={false}
                                className="px-2 py-2 bg-white text-dark"
                            />
                        </div>
                    ))}
                </div>
                <div className="custom-column">
                    {column2Posts.map((post, index) => (
                        <div className="column-item" key={`${index}-${post.id}`}>
                            <GigaPost
                                post={post}
                                isVideoMuted={isVideoMuted}
                                index={index}

                                onMuteChange={handleMuteChange}
                                recommended={false}
                                className="px-2 py-2 bg-white text-dark"
                            />
                        </div>
                    ))}
                </div>
                <div className="custom-column">
                    {column3Posts.map((post, index) => (
                        <div className="column-item" key={`${index}-${post.id}`}>
                            <GigaPost
                                post={post}
                                isVideoMuted={isVideoMuted}
                                onMuteChange={handleMuteChange}
                                recommended={false}
                                index={index}

                                className="px-2 py-2 bg-white text-dark"
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Load More */}
            {nextUrl !== null && (
                <center>
                    <button onClick={handleLoadNext} id="load_more" className="btn btn-sm btn-outline-primary" style={{ marginBottom: "15vh" }}>
                        Load More
                    </button>
                </center>
            )}
        </React.Fragment>
    );
}