import React, { useState } from 'react';
import { apiPostCreate } from './lookup';

export function PostCreate(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [linkVal, setLinkVal] = useState('');
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [uploading, setUploading] = useState(false);
  const textAreaRef = React.createRef();
  const { didPost } = props;
  const [errorMessage, setErrorMessage] = useState("");

  const handleBackendUpdate = (response, status) => {
    if (status === 201) {
      didPost(response);
      console.log(response)
      console.log(response.post_id)
      window.location.href = '/'+ response.post_id;
    } else {
      console.log(response);
      alert(response.error);
    }
    setUploading(false);

  };

  const changeHandler = (event) => {
    const file = event.target.files[0];
    
    // Validate file size (500MB = 500 * 1024 * 1024 bytes)
    if (file.size > 500 * 1024 * 1024) {
      setErrorMessage("The file size should be less than 500MB.");
      return;
    }
  
    // Validate file type
    const acceptedExtensions = ['.gif', '.mp4','.jpeg', '.jpg', '.png', ];

    const fileExtension = "." + file.name.split('.').pop();
    if (!acceptedExtensions.includes(fileExtension.toLowerCase())) {
      setErrorMessage(`Only the following file types are accepted: ${acceptedExtensions.join(', ')}`);
      return;
    }
    
    setSelectedFile(file);
    setIsFilePicked(true);
    setLinkVal('');
    setShowLinkInput(false);
  };

  const toggleLinkInput = () => {
    setShowLinkInput(!showLinkInput);
    setSelectedFile(null);
    setIsFilePicked(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newVal = textAreaRef.current.value;

    apiPostCreate(newVal, selectedFile, handleBackendUpdate, linkVal);

    textAreaRef.current.value = '';
    setLinkVal('');
    setSelectedFile(null);
    setIsFilePicked(false);
    setShowLinkInput(false);
    setUploading(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
  
    const file = e.dataTransfer.files[0];
    
    // Validate file size
    if (file.size > 500 * 1024 * 1024) {
      setErrorMessage("The file size should be less than 500MB.");
      return;
    }
  
    // Validate file type
    const acceptedExtensions = ['.gif', '.mp4','.jpeg', '.jpg', '.png', ];
    const fileExtension = "." + file.name.split('.').pop();
    if (!acceptedExtensions.includes(fileExtension.toLowerCase())) {
      setErrorMessage(`Only the following file types are accepted: ${acceptedExtensions.join(', ')}`);
      return;
    }
    
    setSelectedFile(file);
    setIsFilePicked(true);
    setLinkVal('');
    setShowLinkInput(false);
  };
  
  
  
  
  
  

  const determineFileType = (file) => {
    if (file.type.startsWith('image/')) {
        return 'image';
    } else if (file.type === 'video/mp4') {
        return 'video';
    }
    return 'unknown';
};

const handleDropAreaClick = () => {
  const fileInput = document.getElementById('fileInput');
  if (fileInput) {
    fileInput.click();
  }
};


  return (
    <div
      className={props.className}
      style={{
        borderTop: '0px solid #CED4DA',
        borderRight: '0px solid #CED4DA',
        borderBottom: '0px solid #CED4DA',
        padding: '5px',
      }}
    >
      <form onSubmit={handleSubmit}>
        <textarea
          id="creat-content-input"

          ref={textAreaRef}
          placeholder="New Post"
          required={true}
          className="form-control mb-2"
          style={{ border: '0px', shadow: '0px', resize: 'none' }}
        />

        {/*<hr />  Horizontal line added here */}

        {showLinkInput && (
          <input
          id="creat-link-input"
          type="text"
            className="form-control mb-2"
            placeholder="Enter link"
            value={linkVal}
            onChange={(e) => setLinkVal(e.target.value)}
            style={{ border: '0px', shadow: '0px' }}
          />
        )}


        
        <div style={{paddingLeft: '0px',}}className="d-flex">
          <button
            id="create-post-button"
            style={{ height: '40px',fontSize:'18px',paddingLeft: '15px',paddingRight: '15px', }}
            type="submit"
            className="btn btn-primary d-inline btn-sm"
          >
            Post <i className="fas fa-edit"></i>
          </button>

              
          <label htmlFor="fileInput"style={{}}>
            <input

              name="file"
              type="file"
              id="fileInput"
              hidden
              onChange={changeHandler}
              accept=".jpeg,.jpg,.png,.gif,.mp4" // This line restricts the types of files that can be selected

            />
            <a
              id="create-file-button"

              style={{ marginLeft: '15px', height: '40px',fontSize:'18px',paddingLeft: '15px',paddingRight: '15px', }}
              className="btn btn-outline-primary"
            >
              Upload <i className="fas fa-cloud-upload-alt"></i>
            </a>
          </label>

          <button
            id="create-link-button"
            style={{ height: '40px', marginLeft: '15px',fontSize:'18px',paddingLeft: '15px',paddingRight: '15px',  }}
            type="button"
            className="btn btn-outline-primary"
            onClick={toggleLinkInput}
          >
            {showLinkInput ? 'Cancel' : 'Link'} <i className="fas fa-paperclip"></i>
          </button>
          <div style={{ display: uploading ? 'block' : 'none' }}>
          <div style={{width: '1.75rem',height: '1.75rem',borderWidth: '0.2rem',marginTop:'5px',marginLeft:'15px'}} className="spinner-border spinner-border-sm text-primary" role="status">
              <span className="sr-only"></span>
          </div>
           

          </div>
            


        </div>

        {isFilePicked && (
          <p className=" mx-2 " style={{marginTop:'10px'}}>
            {selectedFile.name} <i style={{fontSize:"14px"}} className="fas fa-check"></i>
          </p>
        )}
      </form>
      {isFilePicked && (
      <div className="file-preview">
          {(() => {
              switch (determineFileType(selectedFile)) {
                  case 'image':
                      return <img src={URL.createObjectURL(selectedFile)} alt="Preview" style={{maxWidth: '100%',maxHeight:'500px'}} />;
                  case 'video':
                      return (
                          <video controls width="100%" style={{maxHeight:'500px'}}>
                              <source src={URL.createObjectURL(selectedFile)} type="video/mp4" />
                              Your browser does not support the video tag.
                          </video>
                      );
                  default:
                      return <p>Cannot preview this file type.</p>;
              }
          })()}
      </div>
  )}
   {!isFilePicked && (
      <div 
      onClick={handleDropAreaClick}
      id="drop-area" 
      className="drop-area"
      style={{
        border: '2px dashed var(--main-border-color)',
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
        cursor: 'pointer',
        borderRadius: '20px',
        color: errorMessage ? 'red' : '#5D656B' // Change text color based on error
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      {errorMessage || "Drag & Drop files here"}
    </div>
    
   )}
   
    </div>
  );
}
