import ReactPlayer from "react-player";
import React,{useState,useRef,useEffect,useCallback} from 'react';
import { ActionBtn,LikeBtn} from './buttons';
import {apiCommentCreate,apiViewPost,apiPostAction} from'./lookup'
import { Popover, PopoverBody } from 'reactstrap';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import{
    UserDisplay,
    UserPicture,

}from'../profiles'

export function ParentPost(props){
    const {post} = props
    return post.parent ? <Post isRepost reposter={props.reposter}hideActions className={'mx-5'} post={post.parent}/>:null
}

export function CommentCreate(props){
    const textAreaRef = React.createRef()
    const {post} = props
    const handleBackendUpdate = (response, status) =>{

        if(status===201){
            window.location.reload();

        }
        if (status===403){
          window.location.href = '/profile/login/'
        }

    }
    const handleSubmit = (event) =>{
        event.preventDefault()
        const newVal = textAreaRef.current.value

        apiCommentCreate(post.id,newVal,handleBackendUpdate)
        textAreaRef.current.value = ''
    }
    return <div className={props.className} style={{"borderTop": "0px solid #CED4DA;",borderRight: "0px solid #CED4DA;",borderBottom: "0px solid #CED4DA;",marginBottom:'10px'}}>
            <div style={{ position: "relative", display: "inline-block",width:'100%',}}>
              <form onSubmit={handleSubmit}>
                  <input 
                      id="creat-comment-input" 
                      ref={textAreaRef} 
                      placeholder="New Comment" 
                      required={true}
                      className="form-control"
                      style={{ 
                          padding: "5px", 
                          paddingRight: "65px",
                          border: "0px",
                          shadow: "0px",
                          resize:"none",
                          width:"100%"
                      }}
                  />
                  <button 
                      id="creat-comment-btn" 
                      type="submit" 
                      className="btn pull-right btn-outline-primary btn-sm" 
                      style={{ 
                          position: "absolute", 
                          right: "0px", 
                          bottom: "0px", 
                          height: "35px", 
                          border: "none" 
                      }}
                  >
                      Post <i className="fas fa-edit"></i>
                  </button>
              </form>
          </div>
            </div>


}
export function timeAgo(date) {
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;  // Rough estimate
  const year = day * 365;

  if (diffInSeconds < minute) {
      return 'Just now';
  } else if (diffInSeconds < hour) {
      return `${Math.floor(diffInSeconds / minute)} min${Math.floor(diffInSeconds / minute) > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < day) {
      const hours = Math.floor(diffInSeconds / hour);
      const minutes = Math.floor((diffInSeconds % hour) / minute);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < week) {
      return `${Math.floor(diffInSeconds / day)} day${Math.floor(diffInSeconds / day) > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < month) {
      return `${Math.floor(diffInSeconds / week)} week${Math.floor(diffInSeconds / week) > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < year) {
      return `${Math.floor(diffInSeconds / month)} month${Math.floor(diffInSeconds / month) > 1 ? 's' : ''} ago`;
  } else {
      return `${Math.floor(diffInSeconds / year)} year${Math.floor(diffInSeconds / year) > 1 ? 's' : ''} ago`;
  }
}



export function Comment(props){
    const {comment,post,className} = props
    let user = {}
    user['imagepath'] = comment[3]
    user['username'] = comment[1]
    const shouldDisplayTimestamp = window.innerWidth > 500;
    const dateString = comment[2];
    const date = new Date(comment[2]);
    const formattedDate = timeAgo(date);
    return (
      <div 
          id="comment-div-outer" 
          className={className} 
          style={{
              wordBreak: "break-word",
              width: "100%",
              padding: "10px",
              borderTop: "0px solid #545454",
              display: "flex",
              flexDirection: "column",
          }}
      >
          {/* Top Section: Profile Picture, Username, and Date */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{marginTop:'15px', marginRight:'6px'}}>
                <UserPicture user={user} />
            </div>

            <UserDisplay user={user} id="comment-text" style={{ marginRight: "auto", paddingLeft: '10px' }} />
            <p className="text-muted" 
              style={{ 
                  margin: 0, 
                  paddingLeft: '10px', 
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
              }}>
              {formattedDate}
            </p>
        </div>
          <div style={{ paddingLeft: "10px", marginTop: "-10px" }}> {/* Adjust marginTop for desired overlap */}
              <p style={{ marginLeft: '47px', marginBottom: '0px' }}>{comment[0]}</p>
              <div>
                {/* Comment and Icons 

                  <i className="fas fa-thumbs-up text-muted" style={{ marginLeft: "47px" }}></i>
                  <i className="fas fa-thumbs-down text-muted" style={{ marginLeft: "10px", position: "relative", top: "3px" }}></i>
                  <i className="fas fa-reply text-muted" style={{ marginLeft: "10px", position: "relative", top: "1px" }}></i>
                  */ }
              </div>
          </div>
      </div>
  );
  
  

}
  

export function Comments(props){
  const {post} = props
  return (
    <React.Fragment>
      {post.comments.length === 0 ? (
        <div id="no-comments-div" style={{display: 'flex', justifyContent: 'center',marginRight:'0px'}}>
          <h4 className="text-muted">Be first to comment!</h4>
        </div>
      ) : (
        post.comments.map((item, index) => (
          <Comment
            comment={item}
            post={post}
            className="my-2 py-2 bg-white text-dark"
            key={`${index}-${item.id}`}
          />
        ))
      )}
    </React.Fragment>
  );
}



export function Message(props){
    const {message} = props
    let className = ""
    const shouldDisplayTimestamp = window.innerWidth > 500;

    return <div className={className} style={{width:"100%",padding:"10px"}}>
                <div><UserPicture user={message.user}/> <UserDisplay user={message.user}/> : {message.content} <div className="text-muted"style={{float:"right",marginRight:"10px"}}>
                    { shouldDisplayTimestamp && 
                      <div>
                     {        new Date(new Date(message.timestamp).getTime() - (4*60*60*1000))
                .toLocaleString("en-US", { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })
}
                      </div>
                    }
                    </div></div>
            </div>

}



export function Conversation(props){
    const {conversation} = props
    let className = ""
    const handleUseLink = (event) =>{
        window.location.href = `/conversation/${conversation.id}/`
    }
    const shouldDisplayTimestamp = window.innerWidth > 500;

    return<div style={{}}>
        <hr></hr>
            <div id="a_conversation" onClick={handleUseLink} className={className} style={{marginTop:"1px",width:"99%",padding:"10px"}}>
            {conversation.otherUser === 'user1' && <div><UserPicture user={conversation.user1}/> @{conversation.user1.username} </div>}
            {conversation.otherUser === 'user2' && <div><UserPicture user={conversation.user2}/>@{conversation.user2.username} </div>}
            {shouldDisplayTimestamp && 
            <div className='text-muted' style={{position:"relative",float:"right",right:"25px",bottom:"32px",height:"25px"}}>

              {        new Date(new Date(conversation.timestamp).getTime() - (4*60*60*1000))
                .toLocaleString("en-US", { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })
              }
            </div>
            }
            </div>
            
            </div>


}



    export function PinnedPost(props){
        const {post, didRepost,hideActions, isRepost,reposter,is_detail} = props
        const [actionPost , setActionPost] = useState(props.post?props.post:null)
        let className = props.className ? props.className : "mx-0"
        if(isRepost === true){
            className=`${className} mx-5`
        }
        //className+=' my-2'
        const path = window.location.pathname
        const match = path.match(/(?<postid>\d+)/)
        const urlPostId = match? match.groups.postid : -1
    
    
    
    
    
        
        const isDetail = `${post.id}` === `${urlPostId}`
        const handleLink = (event) =>{
            event.preventDefault()
            window.location.href = `/${post.id}`
        }
        const handlePerformAction = (newActionPost,status) =>{
            if(status === 200){
                setActionPost(newActionPost)
    
            }else if (status ===201){
                //let post list know
                if(didRepost){
                    didRepost(newActionPost)
                }
            }
        }
        return <div className={className} >
                {isRepost === true && <div className="mb-2 ">
            <span className="small text-muted" >Repost <UserDisplay user={reposter}/></span>
            </div>}
    
            
            <div className='' style={{"height":'45px'}}>
                <UserPicture  style={{"width":'30px'}} user={post.user}/>
                <p className="d-inline" style={{marginTop:'5px'}}>
                    <UserDisplay includeFullName user={post.user}/>
    
                </p>
            </div>
    
    
    
            <div>
            <div className='mx-1 my-1' >
                <p style={{marginLeft: "5px"}}>{post.content}</p>
                {post.imagepath ? <img className="" style={{maxHeight:'800px',maxWidth:'100%',height:'auto'}} src={'https://media.totally.social/media/images/'+post.imagepath}></img>:null}
    
                <ParentPost post={post} reposter={post.user} />
            </div>
            
            <div className="btn btn-group px-0 mx-1" >

            {document.location.host.pathname === true? null:
            <button className='btn btn-sm btn-outline-primary'onClick={handleLink}>View</button>
            }
            </div>
            </div>
    
    
    
        
    
        </div>
        }
    
        
    
    


        





///////////////////////////////////////////////////////////////////////////////////////////////

let popoverIdCounter = 1;



export function Post(props) {
  const { post, didRepost, hideActions, isRepost, reposter, is_detail,loadingNextPage } = props;
  const [actionPost, setActionPost] = useState(props.post ? props.post : null);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverId] = useState(() => `Popover${popoverIdCounter++}`);
  const [timeoutId, setTimeoutId] = useState(null);
  const [likes, setLikes] = useState(post.likes);
  const [isLiked, setIsLiked] = useState(post.is_liked);

  const date = new Date(post.timestamp);
  const formattedDate = timeAgo(date);

  const timestamp = formattedDate;

  const shouldDisplayTimestamp = window.innerWidth > 500;

  const [videoUrl, setVideoUrl] = useState(post.link);
  // Extract the YouTube video ID from the link
  const videoId = post.link ? post.link.split('v=')[1]?.split('&')[0] : '';

  // Form the thumbnail URL using the video ID
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  const [isLoading, setIsLoading] = useState(false);

  const [isMuted, setMuted] = useState(true);
  const [showControls, setShowControls] = useState(true);
  const [viewedPosts, setViewedPosts] = useState([]);



  const [originalVideoUrl, setOriginalVideoUrl] = useState(post.link);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isVideoReady, setVideoReady] = useState(false);
  const [clickedVideoZIndex, setClickedVideoZIndex] = useState(0);

  const [postHover, setPostHover] = useState(false);


  const [isFading, setIsFading] = useState(false);


  const [imageLoaded, setImageLoaded] = useState(false);
  const [justText, setJustText] = useState(false);

  const handleMouseEnterPost = () => {
      setPostHover(true);
      console.log('set true')
    };

  const handleMouseLeavePost = () => {
      setPostHover(false);
    console.log('set false')


  };




  const handleVideoReady = () => {
    setVideoReady(true);
  };





  

  useEffect(() => {

    if (is_detail === true) {
      setShowControls(window.innerWidth > 768)
      setVideoPlaying(true);

    }else{
      setShowControls(false)
    }

  }, []);
  useEffect(() => {
    if (window.location.href.indexOf("feed") <= -1 && window.location.href.indexOf("profile") <= -1 && window.location.href.indexOf("search") <= -1) {
      setMuted(false)
    }
    
  }, []);

  


  const toggle = () => {

    if (popoverOpen) {
      setPopoverOpen(false);
    } else {
      clearTimeout(timeoutId);
      setPopoverOpen(true);
      setTimeoutId(setTimeout(() => setPopoverOpen(false), 3000));
    }
  };
  const videoRef = useRef(null);
  const postRef = useRef(null);


  const handleLink = (event) => {
    if(is_detail !== true){
    event.preventDefault();
    window.location.href = `/${post.id}`;
    }
    event.stopPropagation();
  };


  const likePost = (event) => {
    event.stopPropagation();
    setLikes(likes +1)
    setIsLiked(true)
    console.log('liked post')
    let action={type:"like",display:"Likes"}
    const didPerformAction = (response,status)=>{

    }
    const handleActionBackendEvent = (response,status)=>{
        console.log(response,status)
        if((status === 200||status===201)&& didPerformAction){
            didPerformAction(response,status)

            
        }
        if (status===403){
            window.location.href = '/profile/login/'
        }
    }
    apiPostAction(post.id,action.type,handleActionBackendEvent)

  };
  const repost = (event) => {
    event.stopPropagation();

    console.log('repost')
    let action={type:"repost",display:"repost"}
    const didPerformAction = (response,status)=>{

    }
    const handleActionBackendEvent = (response,status)=>{
        console.log(response,status)
        if((status === 200||status===201)&& didPerformAction){
            didPerformAction(response,status)
            if(action.type ==="repost"){
                window.location.href = "/feed"
            }
            
        }
        if (status===403){
            window.location.href = '/profile/login/'
        }
    }
    apiPostAction(post.id,action.type,handleActionBackendEvent)

  };


  const unlikePost = (event) => {
    event.stopPropagation();

    console.log('liked post')
    setLikes(likes -1)
    setIsLiked(false)
    let action={type:"unlike",display:"unlike"}
    const didPerformAction = (response,status)=>{
      

    }
    const handleActionBackendEvent = (response,status)=>{
        console.log(response,status)
        if((status === 200||status===201)&& didPerformAction){
            didPerformAction(response,status)
            if(action.type ==="repost"){
                window.location.href = "https://totally.social/feed"
            }
            
        }
        if (status===403){
            window.location.href = '/profile/login/'
        }
    }
    apiPostAction(post.id,action.type,handleActionBackendEvent)

  };




  const handlePerformAction = (newActionPost, status) => {
    if (status === 200) {
      setActionPost(newActionPost);
    } else if (status === 201) {
      if (didRepost) {
        didRepost(newActionPost);
      }
    }
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .popover {
        border-color: #ddd !important;
      }
      .popover .arrow::before, .popover .arrow::after {
        display: none !important;
      }
      .popover a {
        color: #333 !important;
        text-decoration: none !important;
      }
      .popover a:hover {
        text-decoration: underline !important;
      }
      #${popoverId}:hover {
        color: #0D6DFD !important;
        font-size: 18px !important;
      }
    `;
    document.head.appendChild(style);
    // Cleanup: remove the style when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
    }, []);


    const anchorClickHandler = (event) => {
      event.stopPropagation();
      // If you want to prevent the default navigation, uncomment the line below
      // event.preventDefault();
      // rest of your logic for the anchor click (if any)
    }
    let contentWithLinks = post.content;
    if (post.content && !isRepost) {
        contentWithLinks = post.content.split(/\s+/).map((word, i) => {
            if (word.startsWith('#')) {
                const hashtag = encodeURIComponent(word);
                return <a onClick={anchorClickHandler} key={i} href={`/search/${hashtag}`} style={{ color: 'var(--main-font-color)', }}>{word}</a>;
            } else {
                return word;
            }
        }).reduce((prev, curr, i) => {
            return i === 0 ? [curr] : [prev, ' ', curr];
        }, '');
    }
    


    


    const [showLabelLike, setShowLabelLike] = useState(false);
    const [showLabelUnlike, setShowLabelUnlike] = useState(false);
    const [showLabelComment, setShowLabelComment] = useState(false);
    const [showLabelView, setShowLabelView] = useState(false);
    const [showLabelRepost, setShowLabelRepost] = useState(false);
  
    const hoverTimeout = useRef(null);


  const likePosthandleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
        setShowLabelLike(true);
    }, 1000);
  }

  const likePosthandleMouseLeave = () => {
    if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
    }
    setShowLabelLike(false);
  }

  const unlikePosthandleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      setShowLabelUnlike(true);
    }, 1000);
  }

  const unlikePosthandleMouseLeave = () => {
    if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
    }
    setShowLabelUnlike(false);
  }


  const commentPosthandleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      setShowLabelComment(true);
    }, 1000);
  }

  const commentPosthandleMouseLeave = () => {
    if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
    }
    setShowLabelComment(false);
  }



  const viewPosthandleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      setShowLabelView(true);
    }, 1000);
  }

  const viewPosthandleMouseLeave = () => {
    if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
    }
    setShowLabelView(false);
  }


  const rePosthandleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      setShowLabelRepost(true);
    }, 1000);
  }

  const rePosthandleMouseLeave = () => {
    if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
    }
    setShowLabelRepost(false);
  }




  let imageMaxHeight = '70vh';
  let mp4MaxHeight = '70vh';
  let imageFit = 'cover';
  let imagePadding ='100%'

  if(is_detail){
    imageMaxHeight = '100%';
    imageFit = 'contain'
    imagePadding = '0px'
  }
  let imageStyles = {
    position: is_detail ? 'relative' : 'absolute',
    top: 0,
    left: 0,
    width: is_detail ? '100%' : '100%',
    height: is_detail ? '100%' : '100%',
    objectFit: is_detail ? 'contain' : 'cover',
    borderRadius: '10px',
    maxHeight: is_detail ? '700px' : '650px',
    transition: 'none',  // No transition when unhovering
};

let mp4PlayerStyle = { 
  maxHeight:  '70vh',
  minHeight:  '40vh',
  bottom: 0, left: 0, 
  pointerEvents: 'auto', borderRadius: '10px' ,
  display: 'flex',
  justifyContent: 'center', // horizontally center
  alignItems: 'center',     // vertically center
  marginBottom: '5px',
  }
  let containerStyles = is_detail ? {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%', // Ensure the container takes full height
    position: 'relative', // Still keep it relative to contain the image
    width: '100%',
    borderRadius: '10px',
    overflow: 'hidden'  // Add this line
  } : {position: 'relative', width: '100%', paddingBottom: imagePadding, borderRadius: '10px', overflow: 'hidden'};


  let containerClasses = ''

  if(is_detail || !props.recommended){
    containerClasses = 'mx-2 my-1';
   
  }
  let noLinkContainerStyles = {};

  if(!is_detail && post.imagepath==null && !post.link){
    noLinkContainerStyles = {padding:'15px',border: '1px solid grey',height:'72%',maxHeight:'56.25',borderRadius:'10px',marginTop:'3px',marginBottom:'20px',marginLeft:'10px',marginRight:'10px'};
  }
  if(post.link){
    if(is_detail||post.link.includes('youtube.com') || post.link.includes('facebook.com') || post.link.includes('twitch.tv')){

    }else{
      noLinkContainerStyles = {padding:'15px',border: '1px solid grey',height:'72%',maxHeight:'56.25',borderRadius:'10px',marginTop:'3px',marginBottom:'20px',marginLeft:'10px',marginRight:'10px'};

    }
  }
  useEffect(() => {
    if(!is_detail && post.imagepath==null && !post.link){
      setJustText(true);

    }
   
    
  }, []);

  useEffect(() => {
    // Create a new Image object
    const imgObj = new Image();

    // Attach the onLoad and onError event handlers
    imgObj.onload = () => {
      setImageLoaded(true);
    };
    imgObj.onerror = () => {
      console.error('Error loading the image.');
    };

    // Start the loading process
    imgObj.src = 'https://media.totally.social/media/images/' + post.imagepath;
  }, [post.imagepath]);

  const urlParams = new URLSearchParams(window.location.search);
  const retrievedTimeFromURL = Number(urlParams.get('time'));
  console.log(retrievedTimeFromURL)

    useEffect(() => {
      if (isVideoReady && videoRef.current) {
          videoRef.current.seekTo(retrievedTimeFromURL, 'seconds');

      }
  }, [isVideoReady, retrievedTimeFromURL]);


  const videoPlayerRef = useRef(null); // Reference to the video player DOM element
  const videoJsPlayerRef = useRef(null); // Reference to the Video.js player instance
  let mp4Style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '10px',
    marginLeft: 'auto',
    marginRight: 'auto'
  };
  
  let videoPlayerStyle = {
    objectFit: 'contain',  // Maintains the aspect ratio
    borderRadius: '10px'
  };
  useEffect(() => {
    const pixelHeight = window.innerHeight * 0.7;
  
    // Initialize Video.js when the component mounts
    if (videoPlayerRef.current) {
      videoJsPlayerRef.current = videojs(videoPlayerRef.current, {
        autoplay: true,
        controls: true,
        height: pixelHeight,
        controlBar: {
          children: [
            'playToggle',
            'progressControl',
            'volumePanel',
            'currentTimeDisplay',
            'fullscreenToggle'
          ],
          pictureInPictureToggle: false
        },
        sources: [{
          src: 'https://media.totally.social/media/images/' + post.imagepath,
          type: 'video/mp4'
        }]
      });
  
      videoJsPlayerRef.current.ready(() => {
        const controlBar = videoJsPlayerRef.current.getChild('controlBar');
        if (retrievedTimeFromURL) {
          videoJsPlayerRef.current.one('canplaythrough', () => {
            videoJsPlayerRef.current.currentTime(retrievedTimeFromURL);
          });
        }
        if (controlBar) {
          const progressBar = controlBar.getChild('progressControl').getChild('seekBar');
          if (progressBar) {
            const thumbnailImg = document.createElement('img');
            const thumbnailUrl = `https://media.totally.social/images/${post.imagepath.replace('.mp4', '')}-1.png`;
            thumbnailImg.src = thumbnailUrl;
            thumbnailImg.classList.add('thumbnail-slider');
            thumbnailImg.style.position = 'absolute';
            thumbnailImg.style.display = 'none';
            thumbnailImg.style.zIndex = '1000';
            thumbnailImg.style.maxWidth = postRef.current.offsetWidth / 4 + 'px';
            thumbnailImg.style.maxHeight = postRef.current.offsetHeight / 7 + 'px';
            thumbnailImg.style.border = '1px solid grey';
            thumbnailImg.style.borderRadius = '10px';
            thumbnailImg.style.objectFit = 'contain';
            thumbnailImg.style.opacity = '0';
            thumbnailImg.style.transition = 'opacity 0.3s';
  
            // Create an overlay for larger hover area
            const hoverOverlay = document.createElement('div');
            hoverOverlay.style.position = 'absolute';
            hoverOverlay.style.top = '-10px'; // Extend the overlay 10px above the progressBar
            hoverOverlay.style.left = '0';
            hoverOverlay.style.right = '0';
            hoverOverlay.style.bottom = '-10px'; // Extend the overlay 10px below the progressBar
            hoverOverlay.style.pointerEvents = 'none'; // Make sure it does not catch mouse events
            progressBar.el().parentNode.insertBefore(hoverOverlay, progressBar.el().nextSibling); // Insert after progressBar

            progressBar.el().appendChild(thumbnailImg);
            hoverOverlay.style.pointerEvents = 'auto'; // Now it can catch mouse events
  
            // Mouse move on hoverOverlay
            
            hoverOverlay.addEventListener('mousemove', (event) => {
              const duration = videoJsPlayerRef.current.duration();
              const mouseX = event.clientX || event.pageX;
              const barStart = progressBar.el().getBoundingClientRect().left;
              const barWidth = progressBar.width();
              
              const hoverPosition = (mouseX - barStart) / barWidth;
              const hoverTime = Math.round(duration * hoverPosition);
  
              const t = `https://media.totally.social/images/${post.imagepath.replace('.mp4', '')}-${hoverTime}.png`;

              
              thumbnailImg.src = t;
              thumbnailImg.style.left = `${mouseX - barStart - (thumbnailImg.offsetWidth / 2)}px`;
              thumbnailImg.style.bottom = '20px';
              thumbnailImg.style.display = 'block';
              thumbnailImg.style.opacity = '1';
              thumbnailImg.style.transition = 'opacity 0.2s, left 0.2s'; // Added transition for left property

            });
  
            // Mouse out on hoverOverlay
            hoverOverlay.addEventListener('mouseout', () => {
              thumbnailImg.style.display = 'none';
              thumbnailImg.style.opacity = '0';
            });
  
          } else {
            console.log('progressBar is not defined');
          }
        } else {
          console.log('controlBar is not defined');
        }
      });
    } else {
      console.log('videoPlayerRef.current is not defined');
    }
  
    // Cleanup when the component unmounts
    return () => {
      if (videoJsPlayerRef.current) {
        videoJsPlayerRef.current.dispose();
      }
    };
  }, [post.imagepath, postRef, videoPlayerRef, retrievedTimeFromURL]);

  return (
    <div style={{ paddingLeft:'25px' }}  onClick={handleLink} ref={postRef} className={props.className ? props.className : 'video-post'}>

      


      <div style={noLinkContainerStyles} >
        <div className={containerClasses} >

       


          <div onClick={handleLink} style={{ height:"100%",display: 'flex'}}>
          

            {post.imagepath ? (
              <>
              {post.imagepath.includes('.mp4') ? (
                <div 
                  style={mp4Style}>
                   <video ref={videoPlayerRef} className="video-js" style={videoPlayerStyle}></video>

                
                </div>

              ) : (
                <>
              {imageLoaded ? (
               <div style={containerStyles}>
               <img
                 className={is_detail ? "" : "expand-on-hover" + (imageLoaded ? " fade-in" : "")}
                 style={imageStyles}
                 src={'https://media.totally.social/media/images/' + post.imagepath}
                 alt="Post Image"
                 onClick={handleLink}
               />
                  {!is_detail && (
                    <div className="icon-container">
                      <i className="fa-solid fa-camera"></i>
                    </div>
                  )}
               
             </div>

              ) : (
                <div style={{ ...containerStyles,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{paddingTop:'50%',position:'absolute'}}>

                <div className="spinner-border-show text-muted" role="status">
                  <span className="sr-only">Loading Posts</span>
                  </div>
                </div>
                </div>

                )}
            </>
              )}
            </>

            ) : null}
          </div>

              {post.link && isRepost !== true && (
                
              <div
               
                style={{
                  marginBottom: '0px',
                  position: 'relative',
                  zIndex: clickedVideoZIndex,
                  borderRadius: '10px',
                  overflow: 'hidden', // This ensures that the border radius is applied to all child elements
                }}
              >
                {post.link.includes('youtube.com') || post.link.includes('facebook.com') || post.link.includes('twitch.tv') ? (
                <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', borderRadius: '10px' }}>
                  {is_detail ? (
                    <ReactPlayer
                      className="react-embed-video-player"
                      ref={videoRef}
                      url={videoUrl}
                      playing={true}
                      muted={false}
                      autoPlay={true}
                      loop={false}
                      controls={true} // Show controls on the detail page
                      width="100%"
                      height="100%"
                      playsinline={true}
                      style={{ position: 'absolute', bottom: 0, left: 0, pointerEvents: 'auto', borderRadius: '10px' }}
                      volume={0.5}
                      onReady={handleVideoReady}
                      seekTo={retrievedTimeFromURL}

                    />
                  ) : (
                    <div>
                   <div className="icon-container">
                      {!isVideoPlaying && (
                        <i className={"fab fa-youtube icon"}></i>
                      )}
                    </div>
                      {isVideoPlaying &&
                        <ReactPlayer
                          className="react-embed-video-player"
                          ref={videoRef}
                          url={videoUrl}
                          playing={isVideoPlaying}
                          muted={true}
                          autoPlay={false}
                          loop={false}
                          seekTo={retrievedTimeFromURL}

                          controls={false}
                          width="100%"
                          height="100%"
                          playsinline={true}
                          style={{ position: 'absolute', bottom: 0, left: 0, pointerEvents: 'none', borderRadius: '10px' }}
                          volume={0.5}
                          onReady={handleVideoReady}
                        />
                      }
                           
                        <div
                        className={isFading ? 'fade-out' : ''}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundImage: `url(https://img.youtube.com/vi/${videoId}/maxresdefault.jpg)`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          borderRadius: '10px', // Apply the border radius if needed
                        }}
                      >
                       
                         <img
                          className={isFading ? 'fade-out' : ''}
                          style={{
                            visibility: 'hidden',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                          src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                          onLoad={(e) => {
                            const img = new Image();
                            img.src = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
                            img.onload = () => {
                              if (img.width < 500) {
                                e.target.style.visibility = 'visible';
                              }
                            };
                          }}
                          alt="Video thumbnail"
                        />
                      
                      </div>
                      
                    </div>
                  )}
                  {!is_detail && (
                    <div
                      onClick={handleLink}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                        pointerEvents: 'auto',
                        borderRadius: '10px',
                      }}
                    />
                  )}
                 
                </div>
              ) : (
                <a href={post.link}>{post.link}</a>
              )}
              </div>
            )}
          <ParentPost post={post} reposter={post.user} />
        </div>
      
        


        <div style={{ marginRight: '0px',
                  marginLeft: '5px'}}>

{isRepost !== true && is_detail === true &&(
        <div style={{float:'right',marginTop:'7px',marginRight:'10px'}}>

          <i id={popoverId}  onClick={(e) => e.stopPropagation()} className="text-muted fas fa-ellipsis-h"></i>
          <Popover placement="bottom-end" isOpen={popoverOpen} target={popoverId} toggle={toggle}>
            <PopoverBody>
              <a href={`/share/${post.id}`}><i class="text-muted fas fa-share"></i> Share</a>
              <br />
              <a href={`/report/${post.id}`}><i class="text-muted fas fa-flag"></i> Report</a>

              {post.is_author && (
                <>
                    <br />
                    <a href={`/delete/${post.id}`}><i className="text-muted fas fa-trash"></i> Delete</a>
                    <br />
                    <a href={`/edit/${post.id}`}><i className="text-muted fas fa-edit"></i> Edit</a>
                </>
            )}
            </PopoverBody>
          </Popover>
        </div>

        )}
{is_detail && (
        <p style={{ marginTop:'0px',marginLeft: '5px' ,marginRight: '5px',wordBreak: "break-word",cursor:'default',fontSize:'22px',marginBottom:'3px'}}>{contentWithLinks}</p>


)}





{(is_detail && isRepost !== true) && (
        <div>
          
          <div 
              id='post-btn-group' 
              style={{
                  marginRight: '5px',
                  marginTop: '5px',
                  marginLeft: '5px',
                  position: 'relative',
                  top: '0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  opacity: is_detail ? 1 : 1,  // if is_detail is true, set opacity to 1, otherwise use the default CSS styles
              }}
              className={postHover ? "" : ""}  // use the post-btn-group class when is_detail is false or postHover is true
              >
              {isLiked === true ? (
                <div onClick={unlikePost}  onMouseEnter={unlikePosthandleMouseEnter} 
                onMouseLeave={unlikePosthandleMouseLeave} className={'action-btn like-btn'} style={{}}>
                <i className="fas fa-heart" style={{fontSize:'15px', marginLeft:"0px", color:"#d41818"}}></i>
                <p className={'text-muted'} style={{fontSize:'16px',  fontWeight:'bold', display:'inline', position: 'relative', top: '1px',cursor:'default',marginLeft:'5px'}}>
                {likes}

                </p>
                <span className={`action-label ${showLabelUnlike ? 'show' : ''}`} >Unlike</span>
                </div>
                

              ):(
                <div  onClick={likePost}  onMouseEnter={likePosthandleMouseEnter} 
                onMouseLeave={likePosthandleMouseLeave}className={'action-btn like-btn'} >
                <i className="far fa-heart text-muted" style={{fontSize:'15px', marginLeft:"0px"}}></i>
                <p className={'text-muted'} style={{fontSize:'16px',  display:'inline', position: 'relative', top: '1px',cursor:'default',marginLeft:'5px'}}>
                {likes}
                </p>  
                <span className={`action-label ${showLabelLike ? 'show' : ''}`}>Like</span>

                </div>

              )}

              <div onClick={handleLink} onMouseEnter={commentPosthandleMouseEnter} 
                onMouseLeave={commentPosthandleMouseLeave} className={'action-btn comment-btn'} >
                <i className="far fa-comment text-muted" style={{fontSize:'15px', marginLeft:"0px"}}></i>
                <p className={'text-muted'} style={{fontSize:'16px',display:'inline', position: 'relative', top: '1px',cursor:'default',marginLeft:'5px'}}>
                {post.commentCount}
                </p>  
                <span className={`action-label ${showLabelComment ? 'show' : ''}`} >Comment</span>

              </div>


              <div onClick={handleLink} onMouseEnter={viewPosthandleMouseEnter} 
                onMouseLeave={viewPosthandleMouseLeave} className={'action-btn views-btn'} >
                <i className="far fa-chart-bar text-muted" style={{fontSize:'15px', marginLeft:"0px"}}></i>
                <p className={'text-muted'} style={{fontSize:'16px',  display:'inline', position: 'relative', top: '1px',cursor:'default',marginLeft:'5px'}}>
                {post.view_count}

                </p>  
                <span className={`action-label ${showLabelView ? 'show' : ''}`}>View</span>

              </div>
       


              <div 
                    style={{marginLeft: 'auto'}} 
                    onClick={handleLink}  
                    className={'action-btn views-btn'} 
                >
                    <i className="far fa-clock text-muted" style={{fontSize:'15px', marginLeft:"0px"}}></i>
                    <p className={'text-muted'} style={{fontSize:'16px',display:'inline', position: 'relative', top: '1px',cursor:'default',marginLeft:'5px'}}>
                        {timestamp}
                    </p>  
                </div>

       

              </div>
     
         
     
        </div>

        
        )}

        {isRepost === true && (
        <div className="mb-2">
          <span className="small text-muted">
            Repost <UserDisplay user={reposter} />
          </span>
          
        </div>
      )}
        
        
      {!is_detail && !justText &&(
      <div className="" style={{ height: '30px', marginTop:'10px'}}>
        
        <UserPicture style={{ width: '20px' }} user={post.user} />
        <p className="d-inline" style={{ marginTop: '3px'}}>
          <UserDisplay user={post.user} /> 
          {(!is_detail && !isRepost === true)  && (
          <p className={'text-muted post-timestamp-display'} style={{fontSize:'16px',display:'inline', position: 'relative', top: '1px',cursor:'default',marginLeft:'5px'}}>
              {timestamp}
          </p> 
          )}

        </p>
        
      </div>
      )}
      {!is_detail && !justText && (
        
          <p style={{
              marginBottom:'3px',
              marginLeft: '5px',
              wordBreak: "break-word",
              cursor: 'default',
              fontSize: '18px',
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "100%"  // Ensures that the content respects its container's width
          }}>
              {contentWithLinks}
          </p>

          )}


      
        
        </div>
      
      {is_detail && (
        <div id="createcomment" style={{ marginTop: '15px' }}>
          <CommentCreate post={post} />
        </div>
      )}

      {is_detail && <>
        <Comments post={post} style={{ marginRight: '0px' }} />
        <br></br>
        <br></br>
      </>
      }
        </div>
        {!is_detail && justText &&(
      <div className="px-2" style={{ height: '30px', marginTop:'-9px',marginBottom:'25px'}}>
        
        <UserPicture style={{ width: '20px' }} user={post.user} />
        <p className="d-inline" style={{ marginTop: '3px'}}>
          <UserDisplay user={post.user} /> 
          {(!is_detail && !isRepost === true)  && (
          <p className={'text-muted post-timestamp-display'} style={{fontSize:'16px',display:'inline', position: 'relative', top: '1px',cursor:'default',marginLeft:'5px'}}>
              {timestamp}
          </p> 
          )}

        </p>
        
      </div>
      )}
    </div>





  );  
}





















export function VideoPost(props) {
  const { post, didRepost, hideActions, isRepost, reposter, is_detail } = props;
  const [actionPost, setActionPost] = useState(props.post ? props.post : null);
  const [originalVideoUrl, setOriginalVideoUrl] = useState(`${post.link}?rel=0&showinfo=0&modestbranding=1`);
  const [videoUrl, setVideoUrl] = useState(originalVideoUrl);
  // Extract the YouTube video ID from the link
  const videoId = post.link ? post.link.split('v=')[1]?.split('&')[0] : '';
  // Form the thumbnail URL using the video ID


  const videoRef = useRef(null);
  const postRef = useRef(null);

  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const uniqueId = useRef(`Popover${popoverIdCounter++}`);
  const [isVideoReady, setVideoReady] = useState(false);


  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });

  const [initialMousePosition, setInitialMousePosition] = useState({ x: 0, y: 0 });
  const [hoverStartTime, setHoverStartTime] = useState(null);


  const [isHovered, setIsHovered] = useState(false);
  const [clickedVideoZIndex, setClickedVideoZIndex] = useState(0);


  const [isFading, setIsFading] = useState(false);

  const handleMouseMove = (event) => {
    if (isVideoPlaying) {
      setLastMousePosition({ x: event.clientX, y: event.clientY });
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isVideoPlaying]);

  useEffect(() => {
    if (isVideoPlaying && initialMousePosition.x === 0 && initialMousePosition.y === 0) {
      setInitialMousePosition(lastMousePosition);
    } else if (!isVideoPlaying) {
      setInitialMousePosition({ x: 0, y: 0 }); // Reset when video stops playing
    }
  }, [isVideoPlaying]);
  
   useEffect(() => {
    const logMousePosition = setInterval(() => {
      if (isVideoPlaying) {
        
        const dx = Math.abs(initialMousePosition.x - lastMousePosition.x);
        const dy = Math.abs(initialMousePosition.y - lastMousePosition.y);
  
        // Calculate hover duration
        const hoverDuration = Date.now() - hoverStartTime;
        if((dx > 250 || dy > 250) && initialMousePosition.x != 0 && initialMousePosition.y != 0 && hoverDuration > 500){
          setIsMouseOver(false);
          setVideoPlaying(false);
          setVideoUrl('Video Unloaded by cords--------------');
          setVideoReady(false);
        }
      }
    }, 1000); // log every 1 second
  
    return () => {
      clearInterval(logMousePosition);
    };
  }, [isVideoPlaying, initialMousePosition, lastMousePosition, hoverStartTime]); // run the effect if either of these change

  var loadTime = Date.now();


  window.addEventListener('blur', function () {
    window.setTimeout(function () {
      if (document.activeElement instanceof HTMLIFrameElement) {
          document.getElementById('overlay').style.display = 'block';
  

          window.location.href = `/${post.id}`;
  
          console.log('iframe click', post.id);
    
      }
    }, 0);
  });

  const handleLink = (event) => {
    setClickedVideoZIndex(3);
    event.preventDefault();
    document.getElementById('overlay').style.display = 'block';
    setTimeout(() => window.location.href = `/${post.id}`, 100);
};

  const handlePerformAction = (newActionPost, status) => {
    if (status === 200) {
      setActionPost(newActionPost);
    } else if (status === 201) {
      if (didRepost) {
        didRepost(newActionPost);
      }
    }
  };

  const handleVideoReady = () => {
    setVideoReady(true);
  };

  const handleVideoPlay = () => {
    if (!isMouseOver) {
      setVideoPlaying(false);
    }
  };
  const handleMouseEnter = () => {
    setIsMouseOver(true);
    setVideoPlaying(true);
    if(post.link.includes('youtube.com') || post.link.includes('facebook.com') || post.link.includes('twitch.tv')){
      setVideoUrl(originalVideoUrl);

    }
    setHoverStartTime(Date.now()); 
    setIsHovered(true);
    setIsFading(true);
  };
  
  const handleMouseLeave = () => {
    setIsMouseOver(false);
    setVideoPlaying(false);
    setVideoUrl('Video Unloaded');
    setVideoReady(false);
    setIsHovered(false);
    setIsFading(false)
  };
  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener('click', handleVideoPlay);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('click', handleVideoPlay);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isMouseOver && isVideoPlaying) {
        handleMouseLeave();
      }
    }, 2000); // check every 2 seconds
  
    return () => {
      clearInterval(interval);
    };
  }, [isMouseOver, isVideoPlaying]); // add isVideoPlaying to the dependency array

  const [hovered, setHovered] = useState(false);

  return (
    <div ref={postRef} className={props.className ? props.className : 'video-post'}>
    <div id="overlay" style={{display: 'none', position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 2, cursor: 'pointer'}}></div>
    <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{marginBottom:'0px', position: 'relative', zIndex: clickedVideoZIndex, border: isHovered ? '1px solid #D1D1D1' : '1px solid transparent',borderRadius: '10px'}}>
            {post.link.includes('youtube.com') || post.link.includes('facebook.com') || post.link.includes('twitch.tv') ? (
            <div className="video-container" style={{ borderRadius: '10px', overflow: 'hidden', position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%'}}>
                {isVideoPlaying &&
                    <ReactPlayer
                    className="react-video-player"
                    ref={videoRef}
                    url={videoUrl}
                    playing={isVideoPlaying}
                    muted={true}
                    autoPlay={false}
                    loop={true}
                    controls={false}
                    width="100%"
                    height="100%"
                    playsinline={true}
                    style={{ position: 'absolute', bottom: 0, left: 0, pointerEvents: 'none' }}
                    volume={0.5}
                    config={{
                        youtube: {
                        playerVars: {
                            showinfo: 0,
                            rel: 0,
                            modestbranding: 1,
                        },
                        },
                    }}
                    onReady={handleVideoReady}
                    />
                  }
                    <div
                    style={{
                        backgroundImage: `url(https://img.youtube.com/vi/${videoId}/maxresdefault.jpg)`,
                        backgroundSize: 'contain',
                        bottom: 0,
                        left: 0,
                    }}
                    >
                    <img
                        className={isVideoReady ? 'fade-out' : ''}
                        style={{
                          visibility: 'hidden',
                          bottom: 0,
                          left: 0,
                      }}
                        src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                        onLoad={(e) => {
                        const img = new Image();
                        img.src = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
                        img.onload = () => {
                            if (img.width < 500) {
                            e.target.style.visibility = 'visible';
                            e.target.style.width = '100%';
                            e.target.style.position = 'absolute';
                            e.target.style.top = '50%'; // Position the top edge at 50% of the container
                            e.target.style.transform = 'translateY(-50%)'; // Shift it up by half its own height
                    
                            }
                        };
                        }}
                        alt="Video thumbnail"
                    />
                    </div>
                <div
                onClick={handleLink}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    pointerEvents: 'auto'
                }}
                />
            </div>
        ) : (
            <a href={post.link}>{post.link}</a>
        )}
          <div
            onClick={handleLink}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
              pointerEvents: 'auto'
            }}
          />
          
        </div>


        <div className="my-2" style={{ height: '30px' }}>
          
          <UserPicture style={{ width: '20px' }} user={post.user} />
          <p className="d-inline" style={{ marginTop: '3px' }}>
            <UserDisplay user={post.user} />
          </p>
          
        </div>
          <p style={{ maxHeight:'25px',minHeight:'25px',marginLeft: '5px' ,wordBreak: "break-word",}}>{post.content}</p>

          </div>
  );
}