import ReactPlayer from "react-player";
import React,{useState,useRef,useEffect,useCallback} from 'react';
import { ActionBtn,LikeBtn} from './buttons';
import {apiCommentCreate,apiViewPost,apiPostAction} from'./lookup'
import { Popover, PopoverBody } from 'reactstrap';
import {Comments, Comment,ParentPost,CommentCreate,timeAgo} from './detail'

import{
    UserDisplay,
    UserPicture,

}from'../profiles'


let popoverIdCounter = 1;

export function GigaPost(props) {

    const { post, isRepost, reposter, is_detail,loadingNextPage,isVideoMuted,onMuteChange,recommended } = props;
    const [isVideoPlaying, setVideoPlaying] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverId] = useState(() => `Popover${popoverIdCounter++}`);
    const [timeoutId, setTimeoutId] = useState(null);

    const date = new Date(post.timestamp);
    const formattedDate = timeAgo(date);
    const timestamp = formattedDate;
      

    const [videoUrl, setVideoUrl] = useState(post.link);
    const videoId = post.link ? post.link.split('v=')[1]?.split('&')[0] : '';


    const [originalVideoUrl, setOriginalVideoUrl] = useState(post.link);


    const [isFading, setIsFading] = useState(false);

    const [imageLoaded, setImageLoaded] = useState(false);

    const [justText, setJustText] = useState(false);

    const [aspectRatio, setAspectRatio] = useState(null);
    const [containerHeight, setContainerHeight] = useState(400);
    const [vContainerHeight, setVContainerHeight] = useState(400); // Initialize to 0
    const [contentOpacity, setContentOpacity] = useState(0); // Initialize to 0

    const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Initial width
    const [textPostHeight, setTextPostHeight] = useState(0);

    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredNoDelay, setIsHoveredNoDelay] = useState(false);
    const [currentVideoTime, setCurrentVideoTime] = useState(0);




    const [isVideoVisible, setVideoVisible] = useState(false);
    const [isReady, setIsReady] = useState(false);

    // First useEffect to set baseHeight

    

    
    

    const fadeTimeoutRef = useRef(null);

    const handleMouseEnter = () => {
      if (is_detail !== true) {
        setVideoPlaying(true);
        setVideoUrl(originalVideoUrl);
        // Clear any previous timeout if it exists
        if (fadeTimeoutRef.current) {
          clearTimeout(fadeTimeoutRef.current);
        }
    
        fadeTimeoutRef.current = setTimeout(() => {
            setIsFading(true);
            
        }, 500);


    
        const callback = (response, status) => {
          // Your callback logic here
        };
        apiViewPost(post.id, callback);
      }
    };
    
    const handleMouseLeave = () => {
      if (is_detail !== true) {
        setVideoPlaying(false);
        setVideoUrl('');
        setIsFading(false);
    
        // Clear the timeout if it exists
        if (fadeTimeoutRef.current) {
          clearTimeout(fadeTimeoutRef.current);
        }
      }
    };




    const videoRef = useRef(null);
    const postRef = useRef(null);


    const handleLink = (event) => {
      if (!event.target.closest('.progress-bar') && !event.target.closest('.progress-bar-container')) {
        event.preventDefault();
        event.stopPropagation();
      }
      if(is_detail !== true){
      event.preventDefault();
      const destinationUrl = `${window.location.origin}/${post.id}?time=${currentVideoTime}`;

      if (event.ctrlKey || event.metaKey) {
          // Open the link in a new tab
          window.open(destinationUrl, '_blank');
      } else {
          const navEvent = new CustomEvent('customNavigate', { detail: destinationUrl });
          window.dispatchEvent(navEvent)
      }
      event.stopPropagation();
    }
    };


    


      const anchorClickHandler = (event) => {
        event.stopPropagation();
      }



      let contentWithLinks = post.content;
      if (post.content && !isRepost) {
          contentWithLinks = post.content.split(/\s+/).map((word, i) => {
              if (word.startsWith('#')) {
                  const hashtag = encodeURIComponent(word);
                  return <a onClick={anchorClickHandler} key={i} href={`/search/${hashtag}`} style={{ color: 'var(--main-font-color)', }}>{word}</a>;
              } else {
                  return word;
              }
          }).reduce((prev, curr, i) => {
              return i === 0 ? [curr] : [prev, ' ', curr];
          }, '');
      }
      


      





let imageStyles = {
    top: 0,
    left: 0,
    width: '100%',
    height:  `${containerHeight}px`,
    objectFit: 'contain',
    borderRadius: '10px',
    transition: 'none',  // No transition when unhovering
    display:'inline-block',
    position:'relative',
};

let mp4Style = {
  top: 0,
  left: 0,
  width: '100%',
  height:  `${vContainerHeight}px`,
  objectFit: 'cover',
  borderRadius: '10px',

  display:'inline-block',
  position:'relative',
  zIndex: isVideoPlaying ? 9999999 : '-20',
  opacity: isReady? 1 : 0,
};



    
    let containerStyles = {
        position: 'relative',
        borderRadius: '10px',
        overflow: 'hidden',
    }


    let containerClasses = ''

    if(is_detail || !props.recommended){
      containerClasses = 'mx-2 my-1';
      
    }
    
    let noLinkContainerStyles = {};

    if(!is_detail && post.imagepath==null && !post.link){
      noLinkContainerStyles = {height: `${textPostHeight}px`,paddingTop:'25px',border: '1px solid grey',borderRadius:'10px',marginTop:'3px',marginBottom:'70px',marginLeft:'0px',marginRight:'0px'};
    }
    if(post.link){
      if(is_detail||post.link.includes('youtube.com') || post.link.includes('facebook.com') || post.link.includes('twitch.tv')){

      }else{
        noLinkContainerStyles = {height: `${textPostHeight}px`,paddingTop:'25px',border: '1px solid grey',borderRadius:'10px',marginTop:'3px',marginBottom:'70px',marginLeft:'0px',marginRight:'0px'};

      }
    }

    useEffect(() => {
      if(!is_detail && post.imagepath==null && !post.link){
        setJustText(true);

      }
      
      
    }, []);

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
      
        // Cleanup
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); // Empty dependency array so this runs only once


    useEffect(() => {
        // Create a new Image object
      if(post.imagepath && !post.imagepath.includes('.mp4')){

        const imgObj = new Image();
        let baseHeight = postRef.current.offsetWidth * 0.5625;  // 56.25% of the width
      
        // Attach the onLoad and onError event handlers
        imgObj.onload = () => {
            // Calculate the aspect ratio of the image
            const ratio = imgObj.width / imgObj.height;
        
            // Calculate the container height based on its width and the image's aspect ratio
            // Here, you may need to replace `postRef.current.offsetWidth` with the actual width of your container
            const calculatedHeight = postRef.current ? postRef.current.offsetWidth / ratio : 0;
        
            // Set the container height
            //setContainerHeight(calculatedHeight);
        
            // Set the aspect ratio state
            setAspectRatio(ratio);
            let newContainerHeight = calculatedHeight;  // default to calculatedHeight

            if (calculatedHeight - baseHeight < 200) {
                newContainerHeight = baseHeight;
            } else {
                newContainerHeight = baseHeight * 2.045 + 79;
            }

            if(newContainerHeight === 0){
                setContainerHeight(postRef.current.offsetWidth * 0.5625);
            }else{
            setContainerHeight(newContainerHeight);

            }
            // Set the imageLoaded state
            setImageLoaded(true);
        };
        imgObj.onerror = () => {
        };
        if (post.id !== null){
            imgObj.src = 'https://media.totally.social/media/images/' + post.imagepath;

        }
      }
        // Start the loading process
      }, [windowWidth]);




      useEffect(() => {
        if(post.link){
          if(is_detail||post.link.includes('youtube.com') || post.link.includes('facebook.com') || post.link.includes('twitch.tv')){
    
          }else{
            const baseHeight = (postRef.current.offsetWidth * 0.5625)-3; // 16:9 aspect ratio
            setTextPostHeight(baseHeight);
          }
        }
        if (justText) {  // `justText` is a variable that indicates it's a text-only post
            const baseHeight = (postRef.current.offsetWidth * 0.5625)-3;  // 16:9 aspect ratio
            setTextPostHeight(baseHeight);
        }
    }, [windowWidth, justText]);

    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });

    const reactPlayerRef = useRef(null);








    useEffect(() => {
      // Create a new Image object
      if(post.imagepath && !post.imagepath.includes('.mp4')){

      const imgObj = new Image();
      let baseHeight = postRef.current.offsetWidth * 0.5625;  // 56.25% of the width
    
      // Attach the onLoad and onError event handlers
      imgObj.onload = () => {
          // Calculate the aspect ratio of the image
          const ratio = imgObj.width / imgObj.height;
      
          // Calculate the container height based on its width and the image's aspect ratio
          // Here, you may need to replace `postRef.current.offsetWidth` with the actual width of your container
          const calculatedHeight = postRef.current ? postRef.current.offsetWidth / ratio : 0;
      
          // Set the container height
          //setContainerHeight(calculatedHeight);
      
          // Set the aspect ratio state
          setAspectRatio(ratio);
          let newContainerHeight = calculatedHeight;  // default to calculatedHeight
          if (calculatedHeight - baseHeight < 200) {
              newContainerHeight = baseHeight;
          setContainerHeight(newContainerHeight);

          } else {
              newContainerHeight = baseHeight * 2.045 + 79;
              if( recommended){
                newContainerHeight = baseHeight*2;
              }
          setContainerHeight(newContainerHeight);

          }

          if(newContainerHeight === 0){
              setContainerHeight(postRef.current.offsetWidth * 0.5625);
          }
          // Set the imageLoaded state
          setImageLoaded(true);
          setContentOpacity(100);
      };
      imgObj.onerror = () => {
      };
      if (post.id !== null){
          imgObj.src = 'https://media.totally.social/media/images/' + post.imagepath;

      }
      }
       
      // Start the loading process
    }, [windowWidth]);

    
    useEffect(() => {

      const imgObj = new Image();
      let baseHeight = postRef.current.offsetWidth * 0.5625;  // 56.25% of the width

      if (thumbnailUrl) {
          imgObj.onload = () => {
            // Calculate the aspect ratio of the image
            const ratio = imgObj.width / imgObj.height;
        
            // Calculate the container height based on its width and the image's aspect ratio
            // Here, you may need to replace `postRef.current.offsetWidth` with the actual width of your container
            const calculatedHeight = postRef.current ? postRef.current.offsetWidth / ratio : 0;
        
            // Set the container height
            //setContainerHeight(calculatedHeight);
        
            // Set the aspect ratio state
            setAspectRatio(ratio);
            let newContainerHeight = calculatedHeight;  // default to calculatedHeight
           if (calculatedHeight - baseHeight < 50 ) {
                newContainerHeight = baseHeight;
            setVContainerHeight(newContainerHeight);

            } else {
                newContainerHeight = baseHeight * 2.045 + 79;
                if( recommended){
                  newContainerHeight = baseHeight*2;
                }
            setVContainerHeight(newContainerHeight);

            }


            if(newContainerHeight === 0){
              setVContainerHeight(postRef.current.offsetWidth * 0.5625);
            }
            if(window.innerWidth < 992){
              setVContainerHeight(500);

            }

            // Set the imageLoaded state
            setImageLoaded(true);
            setContentOpacity(100);
        };
        imgObj.onerror = () => {
        };
        if (post.id !== null){
            imgObj.src = thumbnailUrl;

        }



      }
    }, [videoDimensions,windowWidth]);









    const [finishedSeeking, setFinishedSeeking] = useState(false);


    const handleVideoReady = () => {
      console.log('ready')

      /*
      if (videoRef.current && currentVideoTime > 0 && finishedSeeking !== true) {
        videoRef.current.seekTo(currentVideoTime, 'seconds');
        setFinishedSeeking(true);
      }
      */
      setIsReady(true)

      // Query the video element
      const videoElement = document.querySelector('.react-video-player video');
      
      if (videoElement) {
        const { videoWidth, videoHeight } = videoElement;
        setVideoDimensions({ width: videoWidth, height: videoHeight });
      }
      
    };




    const fadeNativeTimeoutRef = useRef(null);
    const unloadTimeoutRef = useRef(null);

    const handleMouseEnterThumbnail = () => {
        setFinishedSeeking(false);
        
        // Clear any pending delayed unhover actions
        if (unloadTimeoutRef.current) {
            clearTimeout(unloadTimeoutRef.current);
            unloadTimeoutRef.current = null;
        }
    
        if (fadeNativeTimeoutRef.current) {
            clearTimeout(fadeNativeTimeoutRef.current);
        }
    
        setIsHoveredNoDelay(true);
        fadeNativeTimeoutRef.current = setTimeout(() => {
            setVideoVisible(true);
            setIsHovered(true);
            setVideoPlaying(true); // Start the video playback
            apiViewPost(post.id, callback);
        }, 400);
        
      
        const callback = (response, status) => {


        };
    };
    
    const handleMouseLeaveThumbnail = () => {
        setIsHovered(false);
        setVideoVisible(false);
        unloadTimeoutRef.current = setTimeout(() => {
            setIsHoveredNoDelay(false);
        }, 5000); // 5 seconds delay

        setVideoPlaying(false); // Pause the video playback
        if (fadeNativeTimeoutRef.current) {
          clearTimeout(fadeNativeTimeoutRef.current);
        }
    };

    let thumbnailUrl;
    if (post.imagepath && post.imagepath.includes('.mp4')) {
        thumbnailUrl = 'https://media.totally.social/media/images/' + post.imagepath.replace('.mp4', '.jpg');
    }

    let backgroundStyles = {
      backgroundColor: 'rgba(92, 92, 92, 0.05)',  // This is the slight transparent background
      width: '100%',
      height: `${containerHeight}px`,
      display: 'inline-block',
      position: 'relative',
      borderRadius: '10px'
      
    };

    const [progress, setProgress] = useState(0);

    const handleVideoProgress = ({ played, playedSeconds }) => {
      // Use the played variable directly since it's already destructured from the argument
      setProgress(played * 100); // convert to percentage
    
      setCurrentVideoTime(playedSeconds);
      // Here, you can check if the video is muted or not and update accordingly
      if (videoRef.current && !videoRef.current.getInternalPlayer().muted) {
        // If the video is not muted, then update the state
        props.onMuteChange(false); // This will inform the parent that the video is unmuted now
      }
    };

      const [thumbnailSrc, setThumbnailSrc] = useState(thumbnailUrl);
      const [retryCount, setRetryCount] = useState(0);
      const [shouldPreload, SetShouldPreload] = useState(false);


      
      useEffect(() => {

        if(popoverIdCounter < 5 ){
          SetShouldPreload(true);
          preloadImage(thumbnailSrc);
         
        }
    
       
    
    }, []);

      const preloadImage = (src) => {
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'image';
        link.href = src;
        document.head.appendChild(link);
    }
    
    // Call this function with your image's URL


    
    useEffect(() => {
      if (popoverIdCounter < 5) {
          const preloadLink = document.createElement("link");
          preloadLink.href = thumbnailSrc;
          preloadLink.rel = "preload";
          preloadLink.as = "image";
          document.head.appendChild(preloadLink);
  
          // Cleanup after component unmount
          return () => {
              document.head.removeChild(preloadLink);
          };
      }
  }, [thumbnailSrc]);

  const [playerKey, setPlayerKey] = useState(0);
  useEffect(() => {
    if (isHoveredNoDelay) {
        setPlayerKey(prevKey => prevKey + 1);
    }
}, [isHoveredNoDelay]);
    
   

    
    const handlePlayPause = () => {
      setVideoPlaying(!isVideoPlaying);
    };
    const handleProgressBarClick = (e) => {
      console.log('click barred----------')
      // Stop the event from bubbling up to parent elements
      e.preventDefault(); // Prevent default anchor behavior
      e.stopPropagation(); // Stop the event from bubbling up to the anchor
    
      // Calculate the new time based on the click position
      const clickX = e.nativeEvent.offsetX; // Click position within the progress bar
      const totalWidth = e.currentTarget.offsetWidth; // Total width of the progress bar
      const newTimeFraction = clickX / totalWidth; // Fraction of the video to seek to
    
      // Assuming you have the duration of the video, calculate the time in seconds
      const videoDuration = videoRef.current.getDuration(); // Fetch duration from the player
      const newTimeSeconds = videoDuration * newTimeFraction;
    
      // Seek to the new time
      videoRef.current.seekTo(newTimeSeconds, 'seconds');
    };

    const thumbnailRef = useRef(null);


    // At the top of your component, where you declare state variables
    const [hoverTime, setHoverTime] = useState(0);
    const [thumbnailVisibility, setThumbnailVisibility] = useState(false);
    const [sliderThumbnailUrl, setSliderThumbnailUrl] = useState('');
    const handleProgressBarMouseMove = (e) => {
      // Get the bounding rectangle of the progress bar
      const progressBarRect = e.currentTarget.getBoundingClientRect();
      
      // Calculate the mouse position relative to the progress bar
      const mouseX = e.clientX - progressBarRect.left;
      
      if (thumbnailRef && thumbnailRef.current) {
        // Set the left style property of the thumbnail to the mouse X position
        thumbnailRef.current.style.left = `${mouseX}px`;
    
        // Set a fixed bottom style property for the thumbnail to maintain vertical position
        thumbnailRef.current.style.bottom = '14px'; // Adjust this value as needed
    
        thumbnailRef.current.style.visibility = 'visible';
      }
    

      // Ensure the video reference is valid and get the duration
      if (videoRef && videoRef.current && videoRef.current.getInternalPlayer()) {
        const videoDuration = videoRef.current.getInternalPlayer().duration;
        
        // Calculate the new hover time based on the mouse position over the progress bar
        const newHoverTime = (mouseX / progressBarRect.width) * videoDuration;

        // Update the hover time and show the thumbnail
        setHoverTime(newHoverTime);
        setThumbnailVisibility(true);
        
        // Log the hover time for debugging purposes
        console.log(`Hover time: ${newHoverTime} seconds`);
        const roundedHoverTime = Math.round(newHoverTime);

      // Set the thumbnail source dynamically based on roundedHoverTime
      const newThumbnailUrl = `https://media.totally.social/images/${post.imagepath.replace('.mp4', '')}-${roundedHoverTime}.png`;

      // Update the thumbnail source state
      setSliderThumbnailUrl(newThumbnailUrl);
      }
    };
    const handleProgressBarMouseLeave = () => {
      setThumbnailVisibility(false); // Hide the thumbnail
    };
    const [videoDuration, setVideoDuration] = useState(0);
    const handleDuration = (duration) => {
      setVideoDuration(duration);


    };    
      return (
        <a href={`${window.location.origin}/${post.id}`} style={{textDecoration:"none"}}>
            <div onClick={handleLink} ref={postRef} className={props.className ? props.className : 'video-post'}>
                <div style={noLinkContainerStyles}>
                    <div onClick={handleLink} style={{}}>
                        {!is_detail && justText && (
                            <>
                               <div style={{
                                  position: 'relative',
                                  height: `${textPostHeight-29}px`,
                                  overflow: 'hidden',
                                  paddingBottom: '10x',

                              }}>
                                  <p style={{
                                      marginLeft: '5px',
                                      cursor: 'default',
                                      fontSize: '22px',
                                      paddingLeft: '25px',
                                      paddingRight: '25px',
                                  }}>
                                      {contentWithLinks}
                                  </p>
                                  <div style={{
                                      position: 'absolute',
                                      bottom: -1,
                                      left: 0,
                                      right: 0,
                                      height: '20px',
                                      paddding: '10px',
                                      backgroundImage: 'linear-gradient(to top, var(--main-bg-color), transparent)',
                                      width:'95%',
                                      marginLeft: '2.5%'
                                  }} />
                              </div>


                            </>
                        )}
                        {post.imagepath ? (
                <>
                {post.imagepath.includes('.mp4') ? (
                <div className="video-container" style={{height:`${vContainerHeight}px`,}} onMouseEnter={handleMouseEnterThumbnail} onMouseLeave={handleMouseLeaveThumbnail}>
               {thumbnailUrl && (
                   <img 
                   src={thumbnailSrc} 
                   alt="Thumbnail" 
                   style={{height:`${vContainerHeight}px`, borderRadius:'10px', opacity: isHovered ? 0 : 1, zIndex:0, opacity: `${contentOpacity}`}} 
                   className={`thumbnail  ${isReady && isHovered ? "thumbnail-fade fade-transition" : ""}`} 
                   onError={(e) => {
                    if (retryCount < 3) { 
                        setRetryCount(retryCount + 1);
                
                        // Temporarily remove the src attribute
                        e.target.src = '';
                
                        // Wait a little and then try to set the original src again
                        setTimeout(() => {
                            e.target.src = thumbnailSrc;
                        }, 25); // 1-second delay, adjust as needed
                    }
                }}
                 />

                )}
                                    <div 
                                    className={`spinner-border text-muted ${isHovered && !isReady ? "spinner-border-show" : ""}`} 
                                    role="status"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        margin: 'auto',
                                        zIndex: 1
                                    }}
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>

                       
                       <div class="video-wrapper" onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}>
                       {isHoveredNoDelay &&
                       <div>
                          <ReactPlayer
                          key={playerKey}
                          ref={videoRef}
                          className={`react-video-player-native fade-transition ${isReady ? "fade-in" : ""}`}
                          url={'https://media.totally.social/media/images/' + post.imagepath }
                          muted={isVideoMuted || recommended}
                          onDuration={handleDuration}
                          playing = {isVideoPlaying}
                          loop={true}
                          controls={false} // Shasdfow controls on the detail page
                          width="100%"
                          height={`${vContainerHeight}px`}
                          style={mp4Style}
                          volume={0.5}
                          onReady={handleVideoReady}
                          onProgress={handleVideoProgress}
                          progressInterval={100} // Update progress every 0.1 seconds

                        />
                         {isReady && isVideoPlaying &&
                            <>
                              <div className="progress-bar-container" onClick={handleProgressBarClick}
                                    onMouseMove={handleProgressBarMouseMove}
                                    onMouseLeave={handleProgressBarMouseLeave}>
                                  <div className="progress-bar" style={{ width: `${progress}%` }} />
                                  {thumbnailVisibility && 
                                    <img 
                                    src={sliderThumbnailUrl}
                                    className="thumbnail-slider" // Use className instead of class for JSX
                                    alt="Thumbnail" 
                                    ref={thumbnailRef}
                                    onMouseEnter={handleProgressBarMouseLeave}
                                    style={{
                                      position: 'absolute',
                                      bottom: '250%', // Adjust as needed to position the thumbnail above the progress bar
                                      transform: 'translateX(-50%)', // Center the thumbnail above the cursor
                                      width: postRef.current.offsetWidth/5+'px', // Set thumbnail width
                                      height:  postRef.current.offsetHeight/8+'px', // Set thumbnail height
                                      borderRadius: '10px', // Rounded corners
                                      objectFit: 'cover', // Maintain aspect ratio without cropping
                                      boxSizing: 'border-box', // Include padding and borders in the element's total width and height
                                      // Additional styling as needed
                                    }}
                                  />
                                  }
                                </div>

                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent any other click handlers from firing
                                  // Toggle mute state logic here
                                  e.preventDefault(); // Prevent default anchor behavior
                                  if (videoRef.current) {
                                    // Toggle the mute state
                                    const newMutedState = !videoRef.current.getInternalPlayer().muted;
                                    videoRef.current.getInternalPlayer().muted = newMutedState;
                              
                                    // After toggling the mute, inform the parent component about the change
                                  }
                                }}
                                style={{
                                  position: 'absolute',
                                  top: '10px', // Adjust as needed
                                  right: '10px', // Adjust as needed
                                  background: 'none',
                                  border: 'none',
                                  color: 'white', // Adjust color as needed
                                  fontSize: '.8em', // Adjust size as needed
                                  cursor: 'pointer',
                                  zIndex: 99999999, // Make sure it's above the player
                                }}
                                aria-label="Mute/Unmute video"
                              >
                                {/* Toggle between mute and unmute icons based on the current state */}
                                <i className={videoRef.current.getInternalPlayer().muted ? "fas fa-volume-mute" : "fas fa-volume-up"}></i>
                              </button>
                            </>
                          }
                        </div>
                      }

                  </div>

      </div>

                ) : (
                  <>
                {imageLoaded ? (
                    
                    <>
                     <div class="content-background" style={backgroundStyles}> {/* Image container with slightly transparent background */}

                    <img

                        className={"expand-on-hover" + (imageLoaded ? " fade-in" : "")}
                        style={imageStyles}
                        src={'https://media.totally.social/media/images/' + post.imagepath}
                        alt="Post Image"
                        onClick={handleLink}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />
                   </div>

                </>

                ) : (
                  <div style={{ ...containerStyles,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{paddingTop:'0%',marginTop:'50px',marginBottom:'50px'}}>

                </div>
                </div>


                  )}
              </>
                )}
              </>

              ) : null}
            </div>

                {post.link && isRepost !== true && (
                  
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    marginBottom: '0px',
                    position: 'relative',
                    zIndex: 0,
                    borderRadius: '10px',
                    overflow: 'hidden', // This ensures that the border radius is applied to all child elements
                    zIndex: isVideoPlaying ? 9999999 : 'auto'  
                  }}
                >
                  {post.link.includes('youtube.com') || post.link.includes('facebook.com') || post.link.includes('twitch.tv') ? (
                  <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', borderRadius: '10px' }}>
                    {is_detail ? (
                      <ReactPlayer
                        className="react-embed-video-player"
                        ref={videoRef}
                        url={videoUrl}
                        playing={true}
                        muted={false}
                        autoPlay={true}
                        loop={true}
                        controls={true} // Show controls on the detail page
                        width="100%"
                        height="100%"
                        playsinline={true}
                        style={{ position: 'absolute', bottom: 0, left: 0, pointerEvents: 'auto', borderRadius: '10px' }}
                        volume={0.5}
                        onProgress={handleVideoProgress}

                      />
                    ) : (
                      <div>
         
                        {isVideoPlaying &&
                          <ReactPlayer
                            className="react-embed-video-player"
                            ref={videoRef}
                            url={videoUrl}
                            playing={isVideoPlaying}
                            muted={isVideoMuted || recommended}

                            autoPlay={false}
                            loop={true}
                            controls={false}
                            width="100%"
                            height="100%"
                            playsinline={true}
                            style={{ position: 'absolute', bottom: 0, left: 0, pointerEvents: 'none', borderRadius: '10px', }}
                            volume={0.5}
                            onProgress={handleVideoProgress}

                          />
                        }
                              
                          <div
                          className={isFading ? 'fade-out' : ''}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: `url(https://img.youtube.com/vi/${videoId}/maxresdefault.jpg)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: '10px', // Apply the border radius if needed
                          }}
                        >
                          
                            <img
                            className={isFading ? 'fade-out' : ''}
                            style={{
                              visibility: 'hidden',
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                            src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                            onLoad={(e) => {
                              const img = new Image();
                              img.src = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
                              img.onload = () => {
                                if (img.width < 500) {
                                  e.target.style.visibility = 'visible';
                                }
                              };
                            }}
                            alt="Video thumbnail"
                          />
                        
                        </div>
                        
                      </div>
                    )}
                    {!is_detail && (
                      <div
                        onClick={handleLink}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 1,
                          pointerEvents: 'auto',
                          borderRadius: '10px',
                        }}
                      />
                    )}
                    
                  </div>
                ) : (
                  <a href={post.link}>{post.link}</a>
                )}
                </div>
              )}
            <ParentPost post={post} reposter={post.user} />
        
          


        <div style={{ marginRight: '0px', marginLeft: '5px'}}>

 
          
        {!is_detail && (
        <div className="" style={{ height: '30px', marginTop: (post.imagepath && post.imagepath.includes('.mp4')) ? '3px' : '10px' ,overflow:'hidden'}}>
          <UserPicture style={{ width: '20px' }} user={post.user} />
                <p className="d-inline" style={{ marginTop: '3px'}}>
                    <UserDisplay user={post.user} /> 
                    {(!is_detail && !isRepost) && (
                        <span className="text-muted post-timestamp-display" style={{
                          fontSize:'16px', display:'inline-block', position: 'relative', top: '1px', cursor:'default', marginLeft:'5px', textOverflow: "ellipsis"}}>
                            {timestamp}
                            {(post.imagepath && post.imagepath.includes('.mp4')) || 
                            (post.link && (post.link.includes('youtube.com') || post.link.includes('facebook.com') || post.link.includes('twitch.tv'))) ? (
                                <i className="fa-thin fa-circle-play" style={{fontSize:'14px', marginLeft:'10px'}}></i>
                            ) : null}
                            {post.imagepath && !post.imagepath.includes('.mp4') ? (
                                <i className="fa-light fa-camera" style={{fontSize:'14px', marginLeft:'10px'}}></i>
                            ) : null}
                        </span>
                    )}
                </p>
          
        </div>
        )}
        {!is_detail && !justText && (
          
            <p style={{
                marginBottom:'3px',
                marginLeft: '5px',
                wordBreak: "break-word",
                cursor: 'default',
                fontSize: '18px',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "100%"  // Ensures that the content respects its container's width
            }}>
                {contentWithLinks}
               
            </p>

            )}


        
          
          </div>
          </div>

      </div>

      </a>

    );  
  }
