
import React,{useEffect,useState,useRef} from 'react';
import {apiPostFeed,apiPostVideoFeed,apiViewPost} from'./lookup'
import{Post,VideoPost} from './detail'
import {GigaPost} from './gigaPost'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import ReactDOM from 'react-dom';


export function GigaList(props){
    const[postsInit, setPostsInit] = useState([])
    const[posts, setPosts] = useState([])
    const[nextUrl, setNextUrl] = useState(null)
    const[postsDidSet, setPostsDidSet] = useState(false)
    const[loading, setLoading] = useState(false)  // new loading state
    const[loadingNext, setLoadingNext] = useState(false)  // new loading state

    const [value, setValue] = useState(parseInt(localStorage.getItem('insert_index')) || 10);  // retrieve the value from local storage
    const [loadingNextPage, setLoadingNextPage] = useState(false);
    
    const [currentText, setCurrentText] = useState('GPT Filter');
    const [filterValue, setFilterValue] = useState(localStorage.getItem('filterValue') || '');


    const [column1Posts, setColumn1Posts] = useState([]);
    const [column2Posts, setColumn2Posts] = useState([]);
    const [column3Posts, setColumn3Posts] = useState([]);

    const handleSliderChange = (value) => {
        localStorage.setItem('insert_index', value);  // store the value in local storage
        setValue(value);
        setPostsInit([]);  // reset postsInit
        setPosts([]);  // reset posts
        setLoading(true);  // set loading to true when the slider value changes
        console.log(value)
    };

const getShortestColumn = (columns) => {
  let shortest = columns[0];
  for (let column of columns) {
    if (column.current.offsetHeight < shortest.current.offsetHeight) {
      shortest = column;
    }
  }
  return shortest;
};
// First useEffect to handle setting up posts
useEffect(() => {
  let final = [...props.newPosts].concat(postsInit);

  if (final.length > posts.length) {
    setPosts(final);
  }
}, [props.newPosts, postsInit]);
const lastIndexRef = useRef(0);

// Second useEffect to handle adding posts to columns
useEffect(() => {
  console.log('Running column addition useEffect');

  const interval = setInterval(() => {
    console.log('Inside interval, index:', lastIndexRef.current);
    if (lastIndexRef.current < posts.length) {
      console.log('Adding post at index:', lastIndexRef.current);
      const post = posts[lastIndexRef.current];
      if (lastIndexRef.current % 3 === 0) {
        setColumn1Posts(prevPosts => [...prevPosts, post]);
      } else if (lastIndexRef.current % 3 === 1) {
        setColumn2Posts(prevPosts => [...prevPosts, post]);
      } else {
        setColumn3Posts(prevPosts => [...prevPosts, post]);
      }
      lastIndexRef.current++;
    } else {
      console.log('Clearing interval');
      clearInterval(interval);
    }
  }, 75); // Add one post every 0.1 seconds

  // Cleanup interval on component unmount or if the effect runs again
  return () => {
    console.log('Cleaning up interval');
    clearInterval(interval);
  };
}, [posts]);




      useEffect(() =>{
        if(postsDidSet === false || loading === true){  // fetch posts if not set or loading is true
          const callback = (response,status) =>{
            if (status===200){
                console.log(response.next)
                setNextUrl(response.next)
                setPostsInit(response.results)
                setPostsDidSet(true)
                setLoading(false)  // set loading to false after posts have been fetched
                window.NProgress.done();
                console.log('doneloading')

            }
          }
       
          apiPostFeed(callback,value,null)


        }

      },[value])  // added value and loading to dependencies

      useEffect(() =>{
        const callback = (response,status) =>{

        }
        apiViewPost('1',callback);  // Call the endpoint to set the view
    



      },[])  // added value and loading to dependencies

      


    const handleDidRepost = (newPost) =>{
        const updatePostsInit = [...postsInit]
        updatePostsInit.unshift(newPost)
        setPostsInit(updatePostsInit)
        const updateFinalPosts = [...posts]
        updateFinalPosts.unshift(posts)
        setPosts(updateFinalPosts)


    }


    const [lastUrl, setLastUrl] = useState('');

    const handleLoadNext = (e) => {
      e.preventDefault();
      if (nextUrl !== null && nextUrl !== lastUrl) {
        setLastUrl(nextUrl); // Set the last URL to the current nextUrl
        const callback = (response, status) => {
          if (status === 200) {
            setNextUrl(response.next);
            const newPosts = [...posts].concat(response.results);
            setPostsInit(newPosts);
            setPosts(newPosts);
          }
        };
        apiPostFeed(callback, 10, nextUrl);
      }
    };
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        console.log("bottom-----------------------")
        
        if (bottom) { 
            console.log("bottom")
        }
     }
      // Calculate background style based on current value
      useEffect(() => {
        // Get the current value from local storage
        let showFeedSlider = localStorage.getItem('showFeedSlider');
    
        // If the value isn't set yet, initialize it to 'false'
        if (showFeedSlider === null) {
            const feedSliders = document.querySelectorAll('#feed-slider');

            showFeedSlider = 'false';
               feedSliders.forEach((feedSlider) => {
                feedSlider.style.display = showFeedSlider === 'true' ? 'block' : 'none';
            });
            localStorage.setItem('showFeedSlider', showFeedSlider);
        }else if (showFeedSlider === 'false'){
            // Get the feed slider elements
            const feedSliders = document.querySelectorAll('#feed-slider');
                
            // Update their display based on the current value
            feedSliders.forEach((feedSlider) => {
                feedSlider.style.display = showFeedSlider === 'true' ? 'block' : 'none';
            });
        }
       
    }, []);  



    const handleFocus = () => {
        setCurrentText('');
    };
    
    const handleBlur = () => {
        setCurrentText('GPT Filter');
    };
    
    const gptFilter = (event) => {
        console.log('typing in text box')
        const newValue = event.target.value;
        setFilterValue(newValue);
        localStorage.setItem('filterValue', newValue); // Save to local storage
    };


    const handleLoadNextClick = (e) => {
        if(loadingNextPage) return; // If loadingNextPage is true, return here  
        setLoadingNextPage(true); // Set loadingNext to true here
        handleLoadNext(e); // Call your existing handleLoadNext function
        setLoadingNextPage(false); // set it back to false after loading is done

      };


      const [isVideoMuted, setIsVideoMuted] = useState(true);

      function canAutoplayWithSound() {
        return new Promise((resolve, reject) => {
            const audio = new Audio();  // Create an audio element
            audio.src = 'https://www.myinstants.com/media/sounds/1sec_silence.mp3';  // You can use a very short silent audio clip
            audio.volume  = 0.0001;
            audio.play().then(() => {
                resolve(true);  // Autoplay with sound is allowed
            }).catch(() => {
                resolve(false);  // Autoplay with sound is not allowed
            });
        });
    }
    
      // Usage:
      canAutoplayWithSound().then(isAllowed => {
          setIsVideoMuted(!isAllowed);  // Set muted state based on whether autoplay with sound is allowed
      });

      useEffect(() => {
        let intervalId;

      // Check if the user has already seen the welcome message

          intervalId = setInterval(() => {
              canAutoplayWithSound().then(isAllowed => {
                  setIsVideoMuted(!isAllowed);
              });

              // Check inside the interval if the localStorage value has changed
              if (!isVideoMuted) {
                  clearInterval(intervalId);
              }
          }, 250);  // Run every 0.25 seconds

        // Cleanup function to clear interval when component is unmounted
        return () => {
            clearInterval(intervalId);
        };
    }, []);  // Empty dependency array to ensure this runs once when component mounts

       

      const handleMuteChange = (muted) => {
        setIsVideoMuted(muted);
      };
      return (
        <React.Fragment>
          <div id="column-container">
            <div className="custom-column">
              {column1Posts.map((post, index) => (
                <div className="column-item" key={`${index}-${post.id}`}>
                  <GigaPost
                    post={post}
                    is_detail={false}
                    isVideoMuted={isVideoMuted}
                    onMuteChange={handleMuteChange}
                    recommended={false}
                    index={index}

                    didRepost={props.handleDidRepost}
                    className="px-2 py-2 bg-white text-dark"
                  />
                </div>
              ))}
            </div>
            <div className="custom-column">
              {column2Posts.map((post, index) => (
                <div className="column-item" key={`${index}-${post.id}`}>
                  <GigaPost
                      post={post}
                      is_detail={false}
                      isVideoMuted={isVideoMuted}
                      recommended={false}
                      index={index}

                      onMuteChange={handleMuteChange}
                      didRepost={props.handleDidRepost}
                    className="px-2 py-2 bg-white text-dark"
                  />
                </div>
              ))}
            </div>
            <div className="custom-column">
              {column3Posts.map((post, index) => (
                <div className="column-item" key={`${index}-${post.id}`}>
                  <GigaPost
                    post={post}
                    isVideoMuted={isVideoMuted}
                    onMuteChange={handleMuteChange}
                    recommended={false}
                    is_detail={false}
                    didRepost={props.handleDidRepost}
                    index={index}
                    className="px-2 py-2 bg-white text-dark"
                  />
                </div>
              ))}
            </div>
          </div>
          {nextUrl !== null && (
            <center>
              <button
                onClick={handleLoadNextClick}
                id="load_more"
                style={{
                  marginTop: "100px",
                  marginBottom: "20px",
                  border: "None",
                  background: "transparent",
                }}
                className=""
              >
                <div className="spinner-border spinner-border-show text-primary" role="status">
                  <span className="sr-only">Loading Posts</span>
                </div>
              </button>
            </center>
          )}
        </React.Fragment>
      );
    }





export function FeedList(props){
    const[postsInit, setPostsInit] = useState([])
    const[posts, setPosts] = useState([])
    const[nextUrl, setNextUrl] = useState(null)
    const[postsDidSet, setPostsDidSet] = useState(false)
    const[loading, setLoading] = useState(false)  // new loading state
    const[loadingNext, setLoadingNext] = useState(false)  // new loading state

    const [value, setValue] = useState(parseInt(localStorage.getItem('insert_index')) || 10);  // retrieve the value from local storage
    const [loadingNextPage, setLoadingNextPage] = useState(false);
    
    const [currentText, setCurrentText] = useState('GPT Filter');
    const [filterValue, setFilterValue] = useState(localStorage.getItem('filterValue') || '');
    
    
    const handleSliderChange = (value) => {
        localStorage.setItem('insert_index', value);  // store the value in local storage
        setValue(value);
        setPostsInit([]);  // reset postsInit
        setPosts([]);  // reset posts
        setLoading(true);  // set loading to true when the slider value changes
        console.log(value)

    };

    useEffect(()=>{

        let final = [...props.newPosts].concat(postsInit)
        if(final.length !== posts.length){
            setPosts(final)
        }
      },[props.newPosts,posts,postsInit])


      useEffect(() =>{
        if(postsDidSet === false || loading === true){  // fetch posts if not set or loading is true
          const callback = (response,status) =>{
            if (status===200){
                console.log(response.next)
                setNextUrl(response.next)
                setPostsInit(response.results)
                setPostsDidSet(true)
                setLoading(false)  // set loading to false after posts have been fetched
            }
          }
          apiPostFeed(callback,value,null)

        }

      },[value])  // added value and loading to dependencies

      


    const handleDidRepost = (newPost) =>{
        const updatePostsInit = [...postsInit]
        updatePostsInit.unshift(newPost)
        setPostsInit(updatePostsInit)
        const updateFinalPosts = [...posts]
        updateFinalPosts.unshift(posts)
        setPosts(updateFinalPosts)


    }
   const [lastUrl, setLastUrl] = useState('');

    const handleLoadNext = (e) => {
      e.preventDefault();
      if (nextUrl !== null && nextUrl !== lastUrl) {
        setLastUrl(nextUrl); // Set the last URL to the current nextUrl
        const callback = (response, status) => {
          if (status === 200) {
            setNextUrl(response.next);
            const newPosts = [...posts].concat(response.results);
            setPostsInit(newPosts);
            setPosts(newPosts);
          }
        };
        apiPostFeed(callback, 10, nextUrl);
      }
    };
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        console.log("bottom-----------------------")
        
        if (bottom) { 
            console.log("bottom")
        }
     }
      // Calculate background style based on current value
      useEffect(() => {
        // Get the current value from local storage
        let showFeedSlider = localStorage.getItem('showFeedSlider');
    
        // If the value isn't set yet, initialize it to 'false'
        if (showFeedSlider === null) {
            const feedSliders = document.querySelectorAll('#feed-slider');

            showFeedSlider = 'false';
               feedSliders.forEach((feedSlider) => {
                feedSlider.style.display = showFeedSlider === 'true' ? 'block' : 'none';
            });
            localStorage.setItem('showFeedSlider', showFeedSlider);
        }else if (showFeedSlider === 'false'){
            // Get the feed slider elements
            const feedSliders = document.querySelectorAll('#feed-slider');
                
            // Update their display based on the current value
            feedSliders.forEach((feedSlider) => {
                feedSlider.style.display = showFeedSlider === 'true' ? 'block' : 'none';
            });
        }
       
    }, []);  



    const handleFocus = () => {
        setCurrentText('');
    };
    
    const handleBlur = () => {
        setCurrentText('GPT Filter');
    };
    
    const gptFilter = (event) => {
        console.log('typing in text box')
        const newValue = event.target.value;
        setFilterValue(newValue);
        localStorage.setItem('filterValue', newValue); // Save to local storage
    };


    const handleLoadNextClick = (e) => {
        if(loadingNextPage) return; // If loadingNextPage is true, return here  
        setLoadingNextPage(true); // Set loadingNext to true here
        handleLoadNext(e); // Call your existing handleLoadNext function
        setLoadingNextPage(false); // set it back to false after loading is done

      };
    
    return <React.Fragment>

        <div id="feed-slider" className="my-3 py-2 bborder bg-white text-dark" style={{ paddingLeft:"15px",paddingRight:"15px",margin: '20px 0', position: 'relative' }}>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className='text-muted'style={{ userSelect: 'none' }}>Trending</span>
            <div style={{ width:'50%',position: 'absolute', left: '50%', transform: 'translateX(-50%)', top: -7 }}>
            <input id="gpt-filter" onChange={gptFilter} value={filterValue} placeholder={currentText} onFocus={handleFocus} onBlur={handleBlur} type="text" style={{ fontColor:'#CED4DA',width:'100%',textAlign: 'center', borderRadius: '0.375rem', borderColor: 'transparent', borderWidth: '1px', borderStyle: 'solid', boxShadow: 'none' }}/>
            </div>
            <span className='text-muted'style={{ userSelect: 'none' }}>Following</span>
        </div>

        <Slider min={1} max={20} value={value} onChange={handleSliderChange} 
        trackStyle={{ backgroundColor: '#326EFD',height: 10 }} 
        handleStyle={{ borderColor: '#326EFD',height: 20,width: 20, marginTop: -5  }}  
        railStyle={{ height: 10 }}/>
        
        </div>
        <div id="text-box" style={{ position: 'absolute', left: 0, top: 0, right: 0 }}>

        </div>
        {loading ? 
            <div></div> : 
            posts.map((item, index)=>{
            return <Post  
                post={item}
                is_detail={false}
                didRepost = {handleDidRepost}
                className="my-3 py-4 aborder bg-white text-dark"
                key={`${index}-{item.id}`}
                loadingNextPage={loadingNextPage} /> // Added loadingNext prop
            })
        }
  
  { nextUrl !== null&&<center>
    <button onClick={handleLoadNextClick} id="load_more" style={{opacity:1,marginTop:'100px',marginBottom:"20px",border:"None",background:'transparent'}} className="">
        <div className="spinner-border spinner-border-show text-primary" role="status">
          <span className="sr-only" style={{opacity:'1'}}>Loading Posts</span>
        </div>
    </button> 
    
    </center>}
    </React.Fragment>
  }
 



