import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ProfileBadgeSearchComponent,GigaProfileBadgeComponent, ProfileBadgeComponent,ProfileBadgeComponentSmall,ProfileFollowersList,ProfileFollowingList} from './profiles';
import reportWebVitals from './reportWebVitals';
import {PostRecommendedComponent,GigaComponent,FeedComponent, PostComponent ,PostDetailComponent,SearchComponent,ConversationComponent,MessageComponent,PostPinnedComponent,ExploreComponent} from './posts';

import 'plyr/dist/plyr.css';




const appEl = document.getElementById('root')
if(appEl){
  ReactDOM.render( <App />,appEl);
}
const e = React.createElement




function toggleComponents(render) {
  const components = [
    { el: 'chirp', component: PostComponent, selectorType: 'id' },
    { el: 'chirp-explore', component: ExploreComponent, selectorType: 'id' },
    { el: 'chirp-feed', component: FeedComponent, selectorType: 'id' },
    { el: 'chirp-search', component: SearchComponent, selectorType: 'id' },
    { el: 'chirp-conversations', component: ConversationComponent, selectorType: 'id' },
    { el: 'chirp-message', component: MessageComponent, selectorType: 'id' },
    { el: 'chirp-profile-badge-small', component: ProfileBadgeComponentSmall, selectorType: 'class' },
    { el: 'chirp-profile-badge', component: ProfileBadgeComponent, selectorType: 'class' },
    { el: 'search-profile-badge', component: ProfileBadgeSearchComponent, selectorType: 'class' },
    { el: 'giga-profile-badge', component: GigaProfileBadgeComponent, selectorType: 'class' },
    { el: 'recommended-post', component: PostRecommendedComponent, selectorType: 'class' },
    { el: 'chirp-detail', component: PostDetailComponent, selectorType: 'class' },
    { el: 'chirp-pinned', component: PostPinnedComponent, selectorType: 'class' },
    { el: 'chirp-following', component: ProfileFollowingList, selectorType: 'id' },
    { el: 'chirp-followers', component: ProfileFollowersList, selectorType: 'id' },
    { el: 'giga', component: GigaComponent, selectorType: 'id' },
  ];

  components.forEach(({ el, component, selectorType }) => {
    let elements;

    if (selectorType === 'id') {
      elements = [document.getElementById(el)];
    } else {
      elements = Array.from(document.querySelectorAll(`.${el}`));
    }

    elements.forEach((element) => {
      if (element) {
        if (render) {
          ReactDOM.render(e(component, element.dataset), element);
        } else {
          ReactDOM.unmountComponentAtNode(element);
          element.remove();
        }
      }
    });
  });
}


// Initial rendering
toggleComponents(true);


window.addEventListener('customNavigate', function(event) {
  console.log("Attempted to navigate to: ", event.detail);

  toggleComponents(false);

  // Extract post ID from the URL
  const url = new URL(event.detail);
  const pathSegments = url.pathname.split('/');
  const postId = pathSegments[pathSegments.length - 1]; // Assuming the ID is the last segment

  console.log("Navigating to Post ID:", postId);

    //remove top hashtags and search bar
    document.querySelectorAll('.scrolling-wrapper').forEach(div => {
      div.style.display = 'none';
  });

    //remove top hashtags and search bar
    document.querySelectorAll('.remove-shift').forEach(div => {
      div.style.display = 'none';
  });


  let searchBar = document.getElementById('search-bar');
  console.log('search bar---------',searchBar);
  if (searchBar) {
      searchBar.style.display = 'none';
  }


  const newDiv = addNewPostDiv(postId);
  toggleComponents(true);

  
  window.history.pushState(null, '', event.detail);
});





// Function to handle back button clicks
window.addEventListener('popstate', function(event) {
  // Perform any logic you want when the user clicks the back button
  // For example, you could re-render components based on the new URL
  const currentUrl = new URL(window.location.href);
  const pathSegments = currentUrl.pathname.split('/');
  const postId = pathSegments[pathSegments.length - 1];

  // Un-render current components
  toggleComponents(false);
  window.NProgress.start();

  // Add new post Div based on the URL (if necessary)
  const newDiv = addNewPostDiv(postId);
  

  toggleComponents(true);
});



function addNewPostDiv(postId) {
  // Find the parent container by ID
  const parentContainer = document.getElementById("post-container");

  if (parentContainer) {
    // Create a new div
    const newDiv = document.createElement('div');

    // Add classes to the new div
    newDiv.className = 'chirp-detail';

    // Add inline styles to the new div
    newDiv.style.paddingTop = '33px';

    // Add data attributes to the new div
    newDiv.setAttribute('data-post-id', postId);
    newDiv.setAttribute('data-class-name', 'col-6 mx-auto');

    // Append the new div to the parent container
    parentContainer.appendChild(newDiv);
  } else {
    console.error("Parent container not found!");
  }
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




