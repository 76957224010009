
import {backendLookup} from '../lookup'



export function apiProfileDetail(username,callback){

    backendLookup("GET",`/profile/${username}/`,callback)
  
    }

export function apiProfileFollowToggle(username,action,callback){
    const data = {action:`${action && action}`.toLowerCase()}
    backendLookup("POST",`/profile/${username}/follow/`,callback,data)
    
    }

export function apiGetConversation(callback){
    var username = ''
    var x = window.location.href.split('/')
    var username =x[x.length-1]
    console.log(username)
    backendLookup("GET",`/posts/conversations/${username}`,callback)
    
    }
    
export function apiProfileFollowers(username, callback, nextUrl) {
    let endpoint = `/profile/${username}/followers/`;
    
    if(nextUrl != null&&nextUrl !== undefined){
        if (nextUrl.includes('run.app')) {
          endpoint = nextUrl.split("run.app/api")[1]
        }else{
          endpoint = nextUrl.replace("https://totally.social/api","")
          endpoint = endpoint.replace("http://totally.social/api","")
          endpoint = endpoint.replace("http://localhost:8000/api","")
        }
      }
    
    backendLookup("GET", endpoint, callback);
}

export function apiProfileFollowing(username, callback, nextUrl) {
    let endpoint = `/profile/${username}/following/`;

    if(nextUrl != null&&nextUrl !== undefined){
        if (nextUrl.includes('run.app')) {
          endpoint = nextUrl.split("run.app/api")[1]
        }else{
          endpoint = nextUrl.replace("https://totally.social/api","")
          endpoint = endpoint.replace("http://totally.social/api","")
          endpoint = endpoint.replace("http://localhost:8000/api","")
        }
      }
    

    backendLookup("GET", endpoint, callback);
}





