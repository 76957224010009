import React from 'react';
export function UserLink(props){
    const {username} = props
    const handleUserLink = (event) =>{
        event.stopPropagation();

        const destinationUrl = `${window.location.origin}/profile/${username}`;
        if (event.ctrlKey || event.metaKey) {
            // Open the link in a new tab
            window.open(destinationUrl, '_blank');
        } else {
            window.location.href = destinationUrl;
        }
    }
         
    return (
        <a className="pointer custom-link" href={`${window.location.origin}/profile/${username}`}  onClick={handleUserLink}>
            {props.children}
        </a>
    );
} 
export function UserDisplay(props){
    const {user, includeFullName,hideLink} = props
    const nameDisplay = includeFullName===true?`${user.first_name} ${user.last_name} `:null
    const handleUseLink = (event) =>{
        event.stopPropagation();
        
        window.location.href = `/profile/${user.username}`
    }
    return <React.Fragment >
    {nameDisplay} {hideLink === true ? `@${user.username}` :<UserLink style={{zIndex:500}} username={user.username} onClick={handleUseLink}>@{user.username}</UserLink>}
    
    
    </React.Fragment>
}

export function UserPicture(props){
    const {user, hideLink} = props

    const userProfilePic = <img  alt={`${user.username} Profile Picture`} className="mx-1 rounded-circle " style={{height:'30px',width:'40px'}} src={'https://media.totally.social/media/profile_pics/'+user.imagepath}></img>
    return hideLink === true ?userProfilePic: <UserLink username={user.username}>{userProfilePic}</UserLink>
}

export function UserPostDetailPicture(props){
    const {user, hideLink} = props

    const userProfilePic = <img  alt={`${user.username} Profile Picture`} className="mx-1 rounded-circle " style={{marginTop:'4px',height:'55px',width:'65px',position:'absolute'}} src={'https://media.totally.social/media/profile_pics/'+user.imagepath}></img>
    return hideLink === true ?userProfilePic: <UserLink username={user.username}>{userProfilePic}</UserLink>
}
export function GigaUserPictureProfile(props){
    const {user, hideLink} = props

    const userProfilePic = <img alt={`${user.username} Profile Picture`}  className="mx-1 rounded-circle " style={{width:'70px',height:'50px'}} src={'https://media.totally.social/media/profile_pics/'+user.imagepath}></img>
    return  <>

        <UserLink username={user.username}>
        <div style={{width:'100%',paddingLeft:'0px',paddingRight:'2px'}} >
            {userProfilePic}

        </div>
        </UserLink>
    </>
}       

export function UserPictureProfile(props){
    const {user, hideLink} = props

    const userProfilePic = <img alt={`${user.username} Profile Picture`}  className="mx-1 rounded-circle " style={{height:'175px',width:'220px',marginTop:"12px"}} src={'https://media.totally.social/media/profile_pics/'+user.imagepath}></img>
    return hideLink === true ?userProfilePic: <UserLink username={user.username}>{userProfilePic}</UserLink>
}       

export function UserPictureProfileSmall(props){
    const {user, hideLink} = props

    const userProfilePic = <img alt={`${user.username} Profile Picture`}  className="mx-1 rounded-circle " style={{height:'30px',width:'45px'}} src={'https://media.totally.social/media/profile_pics/'+user.imagepath}></img>
    return hideLink === true ?userProfilePic: <UserLink username={user.username}>{userProfilePic}</UserLink>
}       

export function UserPictureProfileMessage(props){
    const {user, hideLink} = props

    const userProfilePic = <img alt={`${user.username} Profile Picture`}  className="mx-1 rounded-circle " style={{height:'100px',width:'150px'}} src={'https://media.totally.social/media/profile_pics/'+user.imagepath}></img>
    return hideLink === true ?userProfilePic: <UserLink username={user.username}>{userProfilePic}</UserLink>
}       