import React,{useEffect,useState} from 'react'
import {apiProfileDetail, apiProfileFollowToggle,apiGetConversation,apiProfileFollowers,apiProfileFollowing} from './lookup'
import {UserDisplay,UserPicture,UserPictureProfile,UserPictureProfileSmall,GigaUserPictureProfile} from './components'
import { Popover, PopoverBody } from 'reactstrap';

import {DisplayCount} from './utils'





function FollowButton(props) {
    const { isFollowing, onClick } = props;
  
    return (
      isFollowing ? (
          <button style={{padding:"4px",width:'100px',height:'40px'}} onClick={onClick} className="btn btn-outline-primary">
            Remove
          </button>
      ) : (
          <button style={{padding:"4px",width:'100px',height:'40px'}} onClick={onClick} className="btn btn-primary">
            Follow
          </button>
      )
    );
  }


function ProfileItem(props) {
    const { profile, onFollowToggle } = props;
    const [isFollowing, setIsFollowing] = useState(profile.is_following);
  
    const handleFollowToggle = (username) => {
        // Determine the action based on the current following status
        const action = isFollowing ? "unfollow" : "follow";
        // Call the API function
        apiProfileFollowToggle(username, action, (response, status) => {
            console.log(response, status);
            if (status !== 200) {
                alert("There was an error toggling follow status.");
            } else {
                // If the status update was successful, update the local state
                setIsFollowing(!isFollowing);
            }
        });
    };
    // Create a user object with the properties expected by UserPicture and UserDisplay
    const user = {
      username: profile.username,
      first_name: profile.first_name,
      last_name: profile.last_name,
      imagepath: profile.imagepath
    };
  
    return (
      <div className="d-flex justify-content-between" style={{ height: '55px', }}>
        <div>
          <UserPicture style={{ width: '50px' }} user={user} />
          <p className="d-inline" style={{ marginTop: '3px' }}>
            <UserDisplay includeFullName={false} user={user} />
          </p>
        </div>
        <FollowButton isFollowing={isFollowing} onClick={() => handleFollowToggle(profile.username)} />
      </div>
    );
  }



  export function ProfileFollowersList() {
    const [profiles, setProfiles] = useState([]);
    const [nextUrl, setNextUrl] = useState(null);
    const [loading, setLoading] = useState(false);
  
    // Get the current URL path
    const path = window.location.pathname;
    // Split the path into segments
    const pathSegments = path.split('/');
    // Get the username from the path
    let username = pathSegments[2];
    // URI decode the username
    username = decodeURIComponent(username);
  
    const loadProfiles = (url) => {
        // If already loading, do nothing
        if (loading) return;
      
        setLoading(true);
        apiProfileFollowers(username, (response, status) => {
          if (status === 200) {
            // Create a set of existing profile IDs
            const existingProfileIds = new Set(profiles.map(profile => profile.id));
            // Filter out profiles that are already in the state
            const uniqueProfiles = response.results.filter(profile => !existingProfileIds.has(profile.id));
            // Append unique profiles to the state
            setProfiles(prevProfiles => [...prevProfiles, ...uniqueProfiles]);
            setNextUrl(response.next);
          }
          setLoading(false);
        }, url);
      };
  
    useEffect(() => {
      loadProfiles();  // Initial load
    }, [username]);
  
    const handleLoadNext = () => {
      if (nextUrl !== null && !loading) {
        loadProfiles(nextUrl);
      }
    };
  
    const handleFollowToggle = (username) => {
      // TODO: Implement follow/unfollow action
    };
  
    return (
      <div>
          {profiles.length > 0 || loading? (
            profiles.map((profile) => (
                <ProfileItem key={profile.id} profile={profile} onFollowToggle={handleFollowToggle} />
            ))
        ) : (
            <h2>Empty</h2>
        )}
        {loading && <div>Loading...</div>}
        {nextUrl !== null && 
          <center>
            <button onClick={handleLoadNext} id="load_more" style={{marginTop:'100px',marginBottom:"20px",border:"None",background:'transparent'}} className="">
                <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading More Followers</span>
                </div>
            </button> 
          </center>}
      </div>
    );
  }




  export function ProfileFollowingList() {
    const [profiles, setProfiles] = useState([]);
    const [nextUrl, setNextUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    // Get the current URL path
    const path = window.location.pathname;
    // Split the path into segments
    const pathSegments = path.split('/');
    // Get the username from the path
    let username = pathSegments[2];
    // URI decode the username
    username = decodeURIComponent(username);

    const loadProfiles = (url) => {
        setLoading(true);
        apiProfileFollowing(username, (response, status) => {
            if (status === 200) {
                setProfiles(prevProfiles => [...prevProfiles, ...response.results]);
                setNextUrl(response.next);
            } 
            setLoading(false);
        }, url);
    };

    useEffect(() => {
        loadProfiles();  // Initial load
    }, [username]);

    const handleLoadNext = () => {
        if (nextUrl !== null && !loading) {
            loadProfiles(nextUrl);
        }
    };

    const handleFollowToggle = (username) => {
        // TODO: Implement follow/unfollow action
    };

    return (
        <div>
            {profiles.length > 0 || loading? (
                profiles.map((profile) => (
                    <ProfileItem key={profile.id} profile={profile} onFollowToggle={handleFollowToggle} />
                ))
            ) : (
                <h2>Empty</h2>
            )}
            {loading && <div>Loading...</div>}
            {nextUrl !== null && 
                <center>
                    <button onClick={handleLoadNext} id="load_more" style={{marginTop:'100px',marginBottom:"20px",border:"None",background:'transparent'}} className="">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading More Following</span>
                        </div>
                    </button> 
                </center>}
        </div>
    );
}





function ProfileSearchBadge(props){
    const {user, didFollowToggle,profileLoading} = props
    let currentVerb =( user && user.is_following) ? "Unfollow":"Follow"
    currentVerb = profileLoading ? "Loading..." :currentVerb
    const handleFollowToggle = (event) =>{
        event.preventDefault()

        if(didFollowToggle && !profileLoading){
            didFollowToggle(currentVerb)
        }
    }
    const handleMessage = (event) =>{
        var getredirect = (response,status) =>{
            console.log(response, status)
            if (status===200){
                window.location.href = '/conversation/'+response+'/'
            }
            if (status===403){
                window.location.href = '/profile/login/'
              }
            }
            apiGetConversation(getredirect)
        }
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
        .popover {
            border-color: #ddd !important;
        }
        .popover .arrow::before, .popover .arrow::after {
            display: none !important;
        }
        .popover a {
            color: #333 !important;
            text-decoration: none !important;
        }
        .popover a:hover {
            text-decoration: underline !important;
        }
        #pop-user:hover {
            color: #0D6DFD !important;
        }
        `;
        document.head.appendChild(style);
        // Cleanup: remove the style when the component unmounts
        return () => {
        document.head.removeChild(style);
        };
    }, []);

    const toggle = () => {
        if (popoverOpen) {
        setPopoverOpen(false);
        } else {
        clearTimeout(timeoutId);
        setPopoverOpen(true);
        setTimeoutId(setTimeout(() => setPopoverOpen(false), 3000));
        }
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const linkStyle = {
        color: 'inherit',
        textDecoration: 'none',
      };
      
      const hoverStyle = {
        textDecoration: 'underline',
      };
      

    return user ? <div className="">

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <div id="profile_pic" style={{ minWidth: "180px", maxWidth: "180px", display: 'flex', alignItems: 'center' }}>
                <UserPictureProfile user={user} hideLink />
            </div>

            <div id="" className="" style={{ textAlign: 'center' }}>
                <h3 className='mb-2' style={{}}><UserDisplay user={user} hideLink /> </h3>
                

            </div>

        </div>

        </div> : null
}






function ProfileBadge(props){
    const {user, didFollowToggle,profileLoading} = props
    let currentVerb =( user && user.is_following) ? "Unfollow":"Follow"
    currentVerb = profileLoading ? "Loading..." :currentVerb
    const handleFollowToggle = (event) =>{
        event.preventDefault()

        if(didFollowToggle && !profileLoading){
            didFollowToggle(currentVerb)
        }
    }
    const handleMessage = (event) =>{
        var getredirect = (response,status) =>{
            console.log(response, status)
            if (status===200){
                window.location.href = '/conversation/'+response+'/'
            }
            if (status===403){
                window.location.href = '/profile/login/'
              }
            }
            apiGetConversation(getredirect)
        }
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
        .popover {
            border-color: #ddd !important;
        }
        .popover .arrow::before, .popover .arrow::after {
            display: none !important;
        }
        .popover a {
            color: #333 !important;
            text-decoration: none !important;
        }
        .popover a:hover {
            text-decoration: underline !important;
        }
        #pop-user:hover {
            color: #0D6DFD !important;
        }
        `;
        document.head.appendChild(style);
        // Cleanup: remove the style when the component unmounts
        return () => {
        document.head.removeChild(style);
        };
    }, []);

    const toggle = () => {
        if (popoverOpen) {
        setPopoverOpen(false);
        } else {
        clearTimeout(timeoutId);
        setPopoverOpen(true);
        setTimeoutId(setTimeout(() => setPopoverOpen(false), 3000));
        }
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const linkStyle = {
        color: 'inherit',
        textDecoration: 'none',
      };
      
      const hoverStyle = {
        textDecoration: 'underline',
      };
      

    return user ? <div className="row">

        <div id="popover-menu" style={{float:'right',top:'15px',marginRight:'0px'}}>

            <i id='pop-user' style={{float:'right',top:'15px',marginRight:'0px'}} className="text-muted fas fa-ellipsis-h"></i>
            <Popover placement="bottom-end" isOpen={popoverOpen} target='pop-user' toggle={toggle}>
            <PopoverBody>
                <a href={`/block/${user.username}`}><i class="text-muted fas fa-flag"></i> block</a>
            </PopoverBody>
            </Popover>
        </div>

        <div className="col-3" id="profile_pic"style={{minWidth:"230px",maxWidth:"230px"}}>
        <UserPictureProfile user={user} hideLink/>
        </div>
        <div id="profile_info"className="col-6 mx-2">
            <h3 className='mb-0'><UserDisplay user={user} hideLink/></h3>
            <p id="followers-link" className='mb-0'><a href={`/profile/${user.username}/followers`} style={linkStyle}>Followers</a> <DisplayCount>{user.follower_count}</DisplayCount> </p>
            <p id="following-link" className='mb-0'><a href={`/profile/${user.username}/following`} style={linkStyle}>Following</a> <DisplayCount>{user.following_count}</DisplayCount></p>
            <p className='mb-2'>{user.status}</p>


            <button onClick={handleFollowToggle} className='btn btn-primary'>{currentVerb}</button>
            {windowWidth > 762 && (
                <button onClick={handleMessage} className='btn btn-outline-primary mx-1'>
                Message
                </button>
            )}

            </div>
            

        </div> : null
}


function GigaProfileBadge(props){
    const {user, didFollowToggle,profileLoading} = props
    let currentVerb =( user && user.is_following) ? "Unfollow":"Follow"
    currentVerb = profileLoading ? "Loading..." :currentVerb
    const handleFollowToggle = (event) =>{
        event.preventDefault()

        if(didFollowToggle && !profileLoading){
            didFollowToggle(currentVerb)
        }
    }
    const handleMessage = (event) =>{
        var getredirect = (response,status) =>{
            console.log(response, status)
            if (status===200){
                window.location.href = '/conversation/'+response+'/'
            }
            if (status===403){
                window.location.href = '/profile/login/'
              }
            }
            apiGetConversation(getredirect)
        }
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
        .popover {
            border-color: #ddd !important;
        }
        .popover .arrow::before, .popover .arrow::after {
            display: none !important;
        }
        .popover a {
            color: #333 !important;
            text-decoration: none !important;
        }
        .popover a:hover {
            text-decoration: underline !important;
        }
        #pop-user:hover {
            color: #0D6DFD !important;
        }
        `;
        document.head.appendChild(style);
        // Cleanup: remove the style when the component unmounts
        return () => {
        document.head.removeChild(style);
        };
    }, []);

    const toggle = () => {
        if (popoverOpen) {
        setPopoverOpen(false);
        } else {
        clearTimeout(timeoutId);
        setPopoverOpen(true);
        setTimeoutId(setTimeout(() => setPopoverOpen(false), 3000));
        }
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const linkStyle = {
        color: 'inherit',
        textDecoration: 'none',
      };
      
      const hoverStyle = {
        textDecoration: 'underline',
      };
      

      return user ? (
        <div style={{ display: 'flex', alignItems: 'start', flexWrap: 'nowrap' }}>
      
          <GigaUserPictureProfile user={user} />
      
          <div style={{ marginRight:'5px',marginLeft: '0px', maxWidth: 'calc(100% - 95px - 80px)' }}>
            <p id="username-display" className='mb-0' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                <UserDisplay user={user} />
            </p>            
            <small className='text-muted' style={{ position: 'relative', top: '-5px' }}>
                <DisplayCount>{user.follower_count}</DisplayCount> followers
            </small>
          </div>
      
          <div style={{ marginLeft: 'auto', marginRight: '0px' }}>

                 {user.is_following ? (
                                <button 
                                style={{ marginTop: '3px', marginBottom: '5px', width: '85px', marginLeft: '2px' }} 
                                onClick={handleFollowToggle} 
                                className='btn btn-outline-primary'
                              >
                        Remove
                    </button>

                ) : (
                    <button 
                    style={{ marginTop: '3px', marginBottom: '5px', width: '85px', marginLeft: '2px' }} 
                    onClick={handleFollowToggle} 
                    className='btn btn-outline-primary'
                  >
                    Follow
                    </button>
                )}
          </div>
      
        </div>
      ) : null;
}

export function GigaProfileBadgeComponent(props){
    const {username} = props
    const [didLookup, setDidLookup] = useState(false)
    const [profile, setProfile] = useState(null)
    const [profileLoading, setProfileLoading] = useState(false)
    const handleBackendLookup = (response,status) =>{
        if(status === 200){
            setProfile(response)
        }


    }
    useEffect(()=>{
        if(didLookup === false){
            apiProfileDetail(username,handleBackendLookup)
            setDidLookup(true)
        }
    },[username,didLookup,setDidLookup])
    const handleNewFollow = (actionVerb) =>{
        apiProfileFollowToggle(username,actionVerb,(response, status)=>{
            if(status === 200){
                setProfile(response)
                
                //apiProfileDetail(username,handleBackendLookup)
            }else{
                window.location.href = '/profile/login/'

            }
          
            setProfileLoading(false)
        })
        setProfileLoading(true)
    }
    
    return  didLookup===false? "loading...":<GigaProfileBadge user={profile} didFollowToggle={handleNewFollow} profileLoading={profileLoading}/>
}


export function ProfileBadgeSearchComponent(props){
    const {username} = props
    const [didLookup, setDidLookup] = useState(false)
    const [profile, setProfile] = useState(null)
    const [profileLoading, setProfileLoading] = useState(false)
    const handleBackendLookup = (response,status) =>{
        if(status === 200){
            setProfile(response)
        }


    }
    useEffect(()=>  {
        if(didLookup === false){
            apiProfileDetail(username,handleBackendLookup)
            setDidLookup(true)
        }
    },[username,didLookup,setDidLookup])
    const handleNewFollow = (actionVerb) =>{
        apiProfileFollowToggle(username,actionVerb,(response, status)=>{
            if(status === 200){
                setProfile(response)
                
                //apiProfileDetail(username,handleBackendLookup)
            }else{
                window.location.href = '/profile/login/'

            }
          
            setProfileLoading(false)
        })
        setProfileLoading(true)
    }
    
    return  didLookup===false? "loading...":<ProfileSearchBadge user={profile} didFollowToggle={handleNewFollow} profileLoading={profileLoading}/>
}

export function ProfileBadgeComponent(props){
    const {username} = props
    const [didLookup, setDidLookup] = useState(false)
    const [profile, setProfile] = useState(null)
    const [profileLoading, setProfileLoading] = useState(false)
    const handleBackendLookup = (response,status) =>{
        if(status === 200){
            setProfile(response)
        }


    }
    useEffect(()=>{
        if(didLookup === false){
            apiProfileDetail(username,handleBackendLookup)
            setDidLookup(true)
        }
    },[username,didLookup,setDidLookup])
    const handleNewFollow = (actionVerb) =>{
        apiProfileFollowToggle(username,actionVerb,(response, status)=>{
            if(status === 200){
                setProfile(response)
                
                //apiProfileDetail(username,handleBackendLookup)
            }else{
                window.location.href = '/profile/login/'

            }
          
            setProfileLoading(false)
        })
        setProfileLoading(true)
    }
    
    return  didLookup===false? "loading...":<ProfileBadge user={profile} didFollowToggle={handleNewFollow} profileLoading={profileLoading}/>
}

export function ProfileBadgeComponentSmall(props){
    const {username} = props
    const [didLookup, setDidLookup] = useState(false)
    const [profile, setProfile] = useState(null)
    const [profileLoading, setProfileLoading] = useState(false)
    const handleBackendLookup = (response,status) =>{
        if(status === 200){
            setProfile(response)
        }


    }
    useEffect(()=>{
        if(didLookup === false){
            apiProfileDetail(username,handleBackendLookup)
            setDidLookup(true)
        }
    },[username,didLookup,setDidLookup])
    const handleNewFollow = (actionVerb) =>{
        apiProfileFollowToggle(username,actionVerb,(response, status)=>{
            if(status === 200){
                setProfile(response)
                //apiProfileDetail(username,handleBackendLookup)
            }else{
                window.location.href = '/profile/login/'

            }
    
            setProfileLoading(false)
        })
        setProfileLoading(true)
    }
    
    return  didLookup===false? "loading...":<ProfileBadgeSmall user={profile} didFollowToggle={handleNewFollow} profileLoading={profileLoading}/>
}



function ProfileBadgeSmall(props){
    const {user, didFollowToggle,profileLoading} = props
    let currentVerb =( user && user.is_following) ? "Unfollow":"Follow"
    currentVerb = profileLoading ? "Loading..." :currentVerb
    const handleFollowToggle = (event) =>{
        event.preventDefault()

        if(didFollowToggle && !profileLoading){
            didFollowToggle(currentVerb)
        }
    }
    const handleMessage = (event) =>{
        var getredirect = (response,status) =>{
            console.log(response, status)
            if (status===200){
                window.location.href = '/conversation/'+response+'/'
            }
            if (status===403){
                window.location.href = '/profile/login/'
            }
    
            }
            apiGetConversation(getredirect)
        }

    return user ? <div className=""style={{display:"flex"}}>
        <div className="" style={{flex:"1",maxWidth:"50px",minWidth:"50px",marginTop:"5px"}}>
        <UserPictureProfileSmall user={user}/>
        </div>
        <div className="col-3 mx-2" style={{flex:"1",minWidth:"82%",maxWidth:"82%"}}>
            <div style={{marginTop:"8px"}}> 
            
            <UserDisplay user={user}  includeFullName={false}/>
            </div>
        </div>
            

        </div> : null
}