import React,{useState,useEffect} from 'react'
import { PostList,PostListSearch } from './list'
import {PostCreate} from './create'
import { apiPostDetail,apiPostRecommended } from './lookup'
import{FeedList,GigaList} from './feed'
import {Post,PinnedPost} from './detail'
import {GigaPost} from './gigaPost'

import{ConversationList,MessageList} from './messages'
import {GigaProfileBadgeComponent} from '../profiles'

export function ConversationComponent(props){

    return <div className="">
        <ConversationList/>
        </div>

}



export function MessageComponent(props){

    return <div className="">
        <MessageList/>
        </div>

}

export function GigaComponent(props){
    const [newPosts, setNewPosts] = useState([])
    const canPost = props.canPost === "false" ? false : true
    const handleNewPost = (newPost) =>{
        let tempNewPosts = [...newPosts]
        tempNewPosts.unshift(newPost)

        setNewPosts(tempNewPosts)

    }
    return <div className={props.className}>
        <div id="post-feed" className="">
        <GigaList newPosts={newPosts} {...props}/>
        </div>
    </div>

}

export function FeedComponent(props){
    const [newPosts, setNewPosts] = useState([])
    const canPost = props.canPost === "false" ? false : true
    const handleNewPost = (newPost) =>{
        let tempNewPosts = [...newPosts]
        tempNewPosts.unshift(newPost)

        setNewPosts(tempNewPosts)

    }
    return <div className={props.className}>
        {canPost === true &&
        <div id="create-post" className="">
        <PostCreate didPost={handleNewPost}className=""style={{borderLeft:'0px'}}/>
        </div> 
        }
        
    </div>

}
//removed for stand alone create page, still using feed component to render create post....
//<div id="post-feed" className="">
//<FeedList newPosts={newPosts} {...props}/>
//</div>



export function SearchComponent(props){
    const [newPosts, setNewPosts] = useState([])
    const canPost = props.canPost === "false" ? false : true
    const handleNewPost = (newPost) =>{
        let tempNewPosts = [...newPosts]
        tempNewPosts.unshift(newPost)

        setNewPosts(tempNewPosts)

    }
    return <div className={props.className}>
        {canPost === true &&
        <div className="">
        <PostCreate didPost={handleNewPost}className=""style={{borderLeft:'0px'}}/>
        </div> 
        }
        <div className="">
        <PostListSearch newPosts={newPosts} {...props}/>
        </div>
    </div>

}


export function PostComponent(props){
    const [newPosts, setNewPosts] = useState([])
    const canPost = props.canPost === "false" ? false : true
    const handleNewPost = (newPost) =>{
        let tempNewPosts = [...newPosts]
        tempNewPosts.unshift(newPost)

        setNewPosts(tempNewPosts)

    }
    return <div className={props.className}>
        {canPost === true &&
        <PostCreate didPost={handleNewPost}className=""/>

        }
        <PostList newPosts={newPosts} {...props}/>
    </div>

}

export function ExploreComponent(props){
    const [newPosts, setNewPosts] = useState([])
    const canPost = props.canPost === "false" ? false : true
    const handleNewPost = (newPost) =>{
        let tempNewPosts = [...newPosts]
        tempNewPosts.unshift(newPost)

        setNewPosts(tempNewPosts)

    }
    return <div className={props.className}>
        {canPost === true &&
        <PostCreate didPost={handleNewPost}className=""/>

        }
        <h2>#Explore</h2>
        <PostList newPosts={newPosts} {...props}/>
    </div>

}

export function PostDetailComponent(props) {
    const { postId } = props;

    const [post, setPost] = useState(null);
    const [postRecommended, setPostRecommended] = useState([]);
    const [showBadge, setShowBadge] = useState(false);
    const [recommendedIndex, setRecommendedIndex] = useState(0);

    // Function to introduce a delay (used for sequential rendering)
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        apiPostDetail(postId, (response, status) => {
            if (status === 200) {
                setPost(response);
            }
        });
    }, [postId]);

    useEffect(() => {
        if (post) {
            delay(5).then(() => { // Introducing a delay before loading the badge
                setShowBadge(true);
            });
        }
    }, [post]);

    useEffect(() => {
        if (showBadge) {
            apiPostRecommended(postId, (response, status) => {
                if (status === 200) {
                    setPostRecommended(response.recommended_posts);
                }
            });
        }
    }, [showBadge, postId]);

    useEffect(() => {
        if (recommendedIndex < postRecommended.length) {
            delay(10).then(() => { // Load each recommended post sequentially
                setRecommendedIndex(recommendedIndex + 1);
            });
        }
    }, [recommendedIndex, postRecommended]);

    return (
        <div className="row" style={{ overflow: 'hidden' }}>
            <div className="col-lg-8">
                {post && <Post post={post} is_detail={true} className={''} />}
            </div>
            <div id="recommended-post-container" className="col-lg-4">
                {showBadge && post && <GigaProfileBadgeComponent username={post.user.username} />}
                {postRecommended.slice(0, recommendedIndex).map((recommendedPostId, index) => (
                    <div key={index} className='mb-4 mr-2 px-3' style={{marginTop:'15px'}}>
                        <PostRecommendedComponent postId={recommendedPostId} className="recommended-post" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export function PostRecommendedComponent(props){
    const {postId} = props
    const [didLookup, setDidLookup] = useState(false)
    const [post, setPost] = useState(null)
    const handleBackendLookup = (response,status) =>{
        if(status === 200){
            setPost(response)
        }
    }
    useEffect(()=>{
        if(didLookup === false){
            apiPostDetail(postId,handleBackendLookup)
            setDidLookup(true)
        }
    },[postId,didLookup,setDidLookup])

    return post === null ? null: <GigaPost post={post} is_detail={false} classname={props.className} recommended={true} />
}

export function PostPinnedComponent(props){
    const {postId} = props
    const [didLookup, setDidLookup] = useState(false)
    const [post, setPost] = useState(null)
    const handleBackendLookup = (response,status) =>{
        if(status === 200){
            setPost(response)
        }
    }
    useEffect(()=>{
        if(didLookup === false){
            apiPostDetail(postId,handleBackendLookup)
            setDidLookup(true)
        }
    },[postId,didLookup,setDidLookup])

    return post === null ? null: <PinnedPost post={post} is_detail={false} classname={props.className}/>
}