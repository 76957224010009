
import {backendLookup,backendLookupCreate} from '../lookup'



export function apiMessagesList(callback,nextUrl){
  let endpoint = '/posts/conversation/'
  var x = window.location.href.split('/')
  var id =x[x.length-2]
  endpoint = endpoint+id
  console.log(endpoint)

  backendLookup("GET",endpoint,callback)

  }

export function apiConversationList(callback,nextUrl){
  let endpoint = '/posts/conversations/'

  backendLookup("GET",endpoint,callback)

  }

export function apiPostCreate(newPost, file, callback, link) {
  console.log('creating post ------------')
  if (file) {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('content', newPost);
    if (link) {
      formData.append('link', link);
    }
    backendLookupCreate('POST', '/posts/create-post/', callback, formData);
  } else {
    const data = {
      content: newPost,
      link: link ? link : ''
    };
    backendLookup('POST', '/posts/create-post/', callback, data);
  }
}

export function apiMessageCreate(newMessage,callback){
  var x = window.location.href.split('/')
  var id =x[x.length-2]
  console.log('asdf',newMessage)
  backendLookup("POST",`/posts/create-message/`,callback,{'content':newMessage,content:newMessage,conversation:id})
}
export function apiCommentCreate(postId,newComment,callback){
  backendLookup("POST",`/posts/${postId}/comment/`,callback,{content:newComment})
}


export function apiViewPost(postId,callback){
  backendLookup("GET",`/posts/${postId}/viewed/`,callback)
}


export function apiPostAction(postId,action,callback){
  const data = {id:postId,action:action}
  backendLookup("POST","/posts/action/",callback,data)
  
  
}


export function apiPostFeed(callback, value, nextUrl){


  let endpoint = `/posts/feed/?insert_index=${value}&page=1` // added page parameter
  
  if(nextUrl != null&&nextUrl !== undefined){
    if (nextUrl.includes('run.app')) {
      endpoint = nextUrl.split("run.app/api")[1]
    }else{
      endpoint = nextUrl.replace("https://totally.social/api","")
      endpoint = endpoint.replace("http://totally.social/api","")
      endpoint = endpoint.replace("http://localhost:8000/api","")
    }
  }

  
  backendLookup("GET", endpoint, callback)
}

export function apiPostVideoFeed(callback,nextUrl){
  let endpoint = '/posts/videofeed/'
  if(nextUrl != null&&nextUrl !== undefined){
    endpoint = nextUrl.replace("https://totally.social/api","")
    endpoint = endpoint.replace("http://totally.social/api","")
    endpoint = endpoint.replace("http://localhost:8000/api","")

  }
  backendLookup("GET",endpoint,callback)

  }


export function apiPostSearch(callback,nextUrl){
  var search = window.location.href.split('/').slice(-1)
  console.log(search)
  let endpoint = `/posts/search/${search}`
  let pageNumber;
  if(nextUrl != null&&nextUrl !== undefined){
    if (nextUrl.includes('run.app')) {
      endpoint = nextUrl.split("run.app/api")[1]
    }else{
      endpoint = nextUrl.replace("https://totally.social/api","")
      endpoint = endpoint.replace("http://totally.social/api","")
      endpoint = endpoint.replace("http://localhost:8000/api","")
    }
  }

  backendLookup("GET",endpoint,callback)
  }

export function apiPostRecommended(post_id,callback){
  let endpoint = `/posts/recommended/${post_id}` // added page parameter
  console.log('endpoint recommended--------', endpoint)
  backendLookup("GET",endpoint,callback)
  }
  
  

export function apiPostList(username,callback,nextUrl){
  let endpoint = '/posts/'
  if(username){
    endpoint = `/posts/?username=${username}`
  }
  if(nextUrl != null&&nextUrl !== undefined){
    if (nextUrl.includes('run.app')) {
      endpoint = nextUrl.split("run.app/api")[1]
    }else{
      endpoint = nextUrl.replace("https://totally.social/api","")
      endpoint = endpoint.replace("http://totally.social/api","")
      endpoint = endpoint.replace("http://localhost:8000/api","")
    }
  }

  backendLookup("GET",endpoint,callback)

  }
  export function apiPostDetail(postId,callback){

    backendLookup("GET",`/posts/${postId}/`,callback)
  
  }

  export function apiHashtagFollow(hashtag, callback){
    const encodedHashtag = encodeURIComponent(hashtag);
    backendLookup("GET", `/posts/hashtag/${encodedHashtag}/`, callback);
}






function extractPageNumber(urlString) {
  const url = new URL(urlString);
  const params = new URLSearchParams(url.search);
  const page = params.get('page');
  return page;
}